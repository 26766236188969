import React, { useEffect } from "react";
import { Route, Switch, Redirect, useRouteMatch, useHistory } from "react-router-dom";
import WaitingRoom from "./WaitingRoom";
import MeetingRoom from "./MeetingRoom/index";
import SessionNotStarted from "./SessionNotStarted";
import LoginPage from "./Login/LoginPage";
import PageNotFound from "./PageNotFound";
import InvalidPage from "./InvalidPage";
import EndSession from "./EndSession";
import "./App.css";
import UserMessages from "./common/userMessage/userMessages";
import VLogo from "../images/white_logo.svg";
import ErrorPage from "./ErrorPage";
import { getRoomId as getRoomIdFromUrl, isIOS, setParamsStorage } from "./utilities/index";
import { displayInvalidPage } from "../redux/slices/globalSlice";
import { setRoomId, loadVideoParams } from "../redux/slices/videoSlice";
import * as storageConfig from "../api/storageConfig";
import { version } from "../../package.json";
import { datadogRum } from "@datadog/browser-rum";
import { useSelector, useDispatch } from "react-redux";
import Whiteboard from "./Whiteboard";
import PatientFeeback from "./Feedback";

function App() {
  const {invalidPage, errorPage, endPage} = useSelector(state => state.global);
  const {displayFeedback} = useSelector(state => state.video);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const token = storageConfig.getToken();
  const history = useHistory();

  useEffect(() => {
    if (!token) return;

    datadogRum.setUser({
      roomId: storageConfig.getRoomId(),
      sessionId: storageConfig.getSessionId(),
      participantId: storageConfig.getParticipantId(),
      userId: storageConfig.getUserId()
    });

    datadogRum.init({
      applicationId: "83871dc1-5967-4473-b3ea-60a485c1da09",
      clientToken: "puba9f98c7bc711bd89eccf4cba2af2a3d8",
      site: "datadoghq.com",
      service: "telehealth---prod",
      sampleRate: 100,
      trackInteractions: true,
      version: version || ""
    });
  }, [token]);

  return (
    <div className={"main-page" + (isIOS ? " iosDevice" : " androidDevice")}>
      <div id="logo-container">
        <VLogo />
      </div>
      <UserMessages />
      {invalidPage && <Redirect to="/telehealth/invalidPage" />}
      {errorPage && <Redirect to="/telehealth/error" />}
      {endPage && displayFeedback && <Redirect to="/telehealth/feedback" />}
      {endPage && !displayFeedback && <Redirect to="/telehealth/end" />}
      <Switch>
        <Route
          path={path + "/login"}
          render={() => {
            const [roomId, sessionId, participantId, accessToken] = getRoomIdFromUrl();
            if(roomId && sessionId && participantId && accessToken) {
              storageConfig.setSessionId(sessionId);
              storageConfig.setRoomId(roomId);
              storageConfig.setToken(accessToken);
              storageConfig.setParticipantId(participantId);

              dispatch(loadVideoParams({roomId, sessionId, participantId}));
              setTimeout(() => {
                history.push("/telehealth/waitingRoom");
              }, 100);
            }

            if (roomId) {
              dispatch(setRoomId(roomId));
              return <LoginPage />;
            }

            dispatch(displayInvalidPage());
          }}
        >
          {/* {<LoginPage />} */}
        </Route>
        <Route
          path={path + "/waitingRoom"}
          render={() => {
            const params = setParamsStorage();
            if (params) {
              dispatch(loadVideoParams(params));
            }

            return <WaitingRoom />;
          }}
        >
          {/* <WaitingRoom /> */}
        </Route>
        <Route path={path + "/meetingRoom"}>
          <MeetingRoom />
        </Route>
        {process.env.NODE_ENV === "development" && <Route path={path + "/whiteboard"}>
          <div className="test">
          <Whiteboard />
          </div>
        </Route>}
        <Route path={path + "/sessionNotStarted"}>
          <SessionNotStarted />
        </Route>
        <Route path={path + "/invalidPage"}>
          <InvalidPage />
        </Route>
        <Route path={path + "/error"}>
          <ErrorPage />
        </Route>
        <Route path={path + "/feedback"}>
          <PatientFeeback />
        </Route>
        <Route path={path + "/end"}>
          <EndSession />
        </Route>
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
}

export default App;
// export default App;
