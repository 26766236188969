import React, { useState } from "react";
import Popup from "../common/Popup";
import PropTypes from "prop-types";

export default function LoginPhone({
  handlePhone,
  userPhone,
  displayErrorMessage,
  checkingPhone,
}) {
  const [phone, setPhone] = useState(userPhone);
  return (
    <div>
      <Popup
        hideCloseBtn={true}
        title="Log In"
        buttons={[
          {
            text: "Next",
            id: "login_next",
            type: "primary",
            disable: checkingPhone,
            onClick: () => {
              handlePhone(phone);
            },
          },
        ]}
      >
        <div className="login-container">
          <div>Please enter your phone number for verification:</div>
          <input
            autoFocus
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                //press enter is like click on "Next"
                event.preventDefault();
                document.getElementById("login_next").click();
              }
            }}
            type="number"
            placeholder="Ex. +123456789"
          />
          {displayErrorMessage && (
            <div className="error-message">
              You have entered an invalid phone. Please try again.
            </div>
          )}
        </div>
      </Popup>
    </div>
  );
}

LoginPhone.propTypes = {
  handlePhone: PropTypes.func,
  userPhone: PropTypes.string,
  displayErrorMessage: PropTypes.bool,
  checkingPhone: PropTypes.bool,
};
