import { ALIGN_OPTIONS, DEFAULT_FONT_SIZE, FONT_OPTIONS, MENU_STATE, PAINT_OPTIONS, PENCIL_OPTIONS, ShapeTypes } from "./constants";

export const getCanvasMesures = (containerId) => {
  try {
    const $container = document.getElementById(containerId);
    const styles = $container.getBoundingClientRect();

    return [
      Math.ceil(styles.width),
      Math.ceil(styles.height - 50)
    ];
  } catch (error) {
    return [1000, 560];
  }
};

export const drawDiamond = (context, x = 200, y = 200, width = 70, height = 50) => {
  context.beginPath();
  context.moveTo(x, y);
  context.lineTo(x - width / 2, y + height / 2);
  context.lineTo(x, y + height);
  context.lineTo(x + width / 2, y + height / 2);
  context.closePath();
};

export const downloadContent = (url, title) => {
  let link = document.createElement("a");
  link.href = url;
  link.download = title;
  link.click();
};

export const handleDragEnd = (e, shape) => {
  return {
    ...shape,
    x: e.target.x(),
    y: e.target.y()
  };
};

export const handleTransformerEnd = ($ref, shape) => {
  // transformer is changing scale of the node
  // and NOT its width or height
  // but in the store we have only width and height
  // to match the data better we will reset scale on transform end
  const node = $ref.current;

  if (!node) return shape;

  const scaleX = node.scaleX();
  const scaleY = node.scaleY();

  // we will reset it back
  node.scaleX(1);
  node.scaleY(1);
  let newShape = { ...shape };
  newShape.x = node.x();
  newShape.y = node.y();

  newShape.width = Math.ceil(Math.max(5, node.width() * scaleX));
  newShape.height = Math.ceil(Math.max(node.height() * scaleY));
  newShape.rotation = node.rotation();

  return newShape;
};

export const deleteShape = (list, id) => {
  if (!list || !id) return [];

  return list.filter((shape) => {
    return shape.id !== id;
  });
};

export const getStyle = (width, height) => {
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  const baseStyle = {
    width: `${width}px`,
    height: `${height}px`,
    border: "none",
    padding: "0px",
    margin: "0px",
    background: "none",
    outline: "none",
    resize: "none",
    colour: "black",
    fontSize: "24px",
    fontFamily: "sans-serif",
  };

  if (isFirefox) {
    return baseStyle;
  }

  return {
    ...baseStyle,
    margintop: "-4px",
    width: undefined,
    height: undefined
  };
};

export const getMenuBarType = (type) => {
  if (type === ShapeTypes.pen) return MENU_STATE.draw;
  if (type === ShapeTypes.text) return MENU_STATE.text;

  return MENU_STATE.shape;
};

export const getShapeTypeAndFont = (selectedShape) => {
  let type = MENU_STATE.text, options;

  if (selectedShape.type === ShapeTypes.text) {
    if (!selectedShape.fontOptions) {
      options = {
        fontSize: DEFAULT_FONT_SIZE,
        bold: false,
        underline: false,
        align: ALIGN_OPTIONS.left
      };
    } else {
      options = selectedShape.fontOptions;
    }
  } else if (selectedShape.type === ShapeTypes.pen) {
    type = MENU_STATE.draw;
    options = {
      pencil: selectedShape.fontOptions
        ? selectedShape.fontOptions.pencil
        : FONT_OPTIONS.default
    };
  } else {
    type = MENU_STATE.shape;
    options = {
      paint: selectedShape.fontOptions
        ? selectedShape.fontOptions.paint
        : PAINT_OPTIONS.stroke
    };
  }

  return [type, options];
}

export const getPencilWidth = (options) => {
  if(!options || !options.pencil) return 5;

  switch (options.pencil) {
    case FONT_OPTIONS.default:
      return 5
    case FONT_OPTIONS.big:
      return 15
    case FONT_OPTIONS.small:
      return 2
  
    default:
      return 5;
  }

}