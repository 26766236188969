import React from "react";
import PropTypes from "prop-types";

const CallingPatient = ({ patient, ringing }) => {
  return (
    <>
      {patient && (
        <div className="patient-details">
          <img src={patient.picture} width="100" height="100" />
          <div className="patient-name">
            {patient.firstName + " " + patient.lastName}
          </div>
          {ringing && <div className="patient-calling">Ringing...</div>}
        </div>
      )}
    </>
  );
};

CallingPatient.propTypes = {
  patient: PropTypes.object,
  ringing: PropTypes.bool,
};

export default CallingPatient;
