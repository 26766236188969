
import React, { useEffect, useRef } from "react";
import {
  Rect,
  Transformer,
  Circle,
  RegularPolygon,
  Shape,
  Line,
  Image,
  Text,
  Path
} from "react-konva";
import {
  drawDiamond,
  getPencilWidth,
  getStyle,
  handleDragEnd,
  handleTransformerEnd
} from "./domain";
import PropTypes from "prop-types";
import { DEFAULT_FONT_SIZE, ShapeTypes } from "./constants";
import { Html } from "react-konva-utils";

const WhiteboardShape = ({
  shape,
  isSelected,
  avoidSelect,
  onChange,
  defaultColor,
  handleSelectShape
}) => {
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected && !shape.cannotBeSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const commonProps = {
    x: shape.x || 10,
    y: shape.y || 10,
    draggable: !avoidSelect && shape.type !== ShapeTypes.pen,
    onClick: handleSelectShape,
    onDragStart: handleSelectShape,
    onDragEnd: (e) => {
      const newShape = handleDragEnd(e, shape);
      onChange(newShape);
    },
    onTransformEnd: () => {
      const newShape = handleTransformerEnd(shapeRef, shape);
      onChange(newShape);
    }
  };

  if (shape.fontOptions) {
    if (shape.fontOptions.paint) {
      commonProps[shape.fontOptions.paint] = shape.color;
    }
  }

  let SelectedShape;
  switch (shape.type) {
    case ShapeTypes.circle:
      SelectedShape = (
        <Circle
          {...commonProps}
          radius={shape.radius || 50}
          width={shape.width || 30}
          height={shape.height || 30}
          ref={shapeRef}
        />
      );
      break;
    case ShapeTypes.triangle:
      SelectedShape = (
        <RegularPolygon
          {...commonProps}
          radius={70}
          sides={3}
          ref={shapeRef}
          width={shape.width || 30}
          height={shape.height || 30}
          rotation={shape.rotation || undefined}
        />
      );

      break;
    case ShapeTypes.pentagone:
      SelectedShape = (
        <RegularPolygon
          {...commonProps}
          radius={70}
          sides={5}
          ref={shapeRef}
          width={shape.width || 30}
          height={shape.height || 30}
          rotation={shape.rotation || undefined}
        />
      );
      break;
    case ShapeTypes.hexagon:
      SelectedShape = (
        <RegularPolygon
          {...commonProps}
          radius={70}
          sides={6}
          ref={shapeRef}
          width={shape.width || 30}
          height={shape.height || 30}
          rotation={shape.rotation || undefined}
        />
      );
      break;
    case ShapeTypes.diamond:
      //TODO: check
      SelectedShape = (
        <Shape
          sceneFunc={(context, _shape) => {
            drawDiamond(context, shape.x, shape.y, shape.width, shape.height);

            context.fillStrokeShape(_shape);
          }}
          stroke={shape.stroke || defaultColor}
          strokeWidth={4}
          draggable
          ref={shapeRef}
        />
      );
      break;
    case ShapeTypes.stamp:
      {
        const img = document.createElement("img");
        img.src = "data:image/svg+xml;base64," + window.btoa(shape.data);

        SelectedShape = (
          <Image
          {...commonProps}
            image={img}
            width={shape.width}
            height={shape.height}
            fill={shape.color}
            scale={shape.scale}
            rotation={shape.rotation}
            ref={shapeRef}
          />
        );
      }
      break;
    case ShapeTypes.text:
      {
        const toggleEdit = () => {
          onChange({ ...shape, isEditing: !shape.isEditing, cannotBeSelected: !shape.isEditing });
        };
        const fontOptions = shape.fontOptions || {};

        if (!shape.isEditing) {
          SelectedShape = (
            <Text
              {...commonProps}
              ref={shapeRef}
              text={shape.text}
              textDecoration={fontOptions.underline ? "underline" : ""}
              fontSize={fontOptions.fontSize || DEFAULT_FONT_SIZE}
              fontStyle={fontOptions.bold ? "bold" : "normal"}
              align={fontOptions.align || "left"}
              fill={shape.color || "black"}
              fontFamily="sans-serif"
              perfectDrawEnabled={false}
              onDblClick={toggleEdit}
              onDblTap={toggleEdit}
              width={shape.width}
              height={shape.height}
              draggable
            />
          );
        } else {
          const style = getStyle(shape.width, shape.height);
          SelectedShape = (
            <Html
              groupProps={{ x: shape.x, y: shape.y }}
              divProps={{ style: { opacity: 1 } }}
            >
              <textarea
                value={shape.text}
                onChange={(e) => {
                  onChange({ ...shape, text: e.target.value });
                }}
                style={style}
                rows={10}
                cols={50}
              />
            </Html>
          );
        }
      }
      break;
    case ShapeTypes.pen:
      SelectedShape = (
        <Line
          points={shape.points}
          draggable={false}
          stroke={!shape.erase ? shape.color || "#df4b26" : "white"}
          strokeWidth={getPencilWidth(shape.fontOptions)}
          tension={0.5}
          opacity={shape.opacity}
          lineCap="round"
          onClick={handleSelectShape}
          onDragEnd={handleDragEnd}
          onTransformEnd={handleTransformerEnd}
          globalCompositeOperation="source-over"
        />
      );
      break;
    case ShapeTypes.image:{
      SelectedShape = (
        <Image
          {...commonProps}
          width={shape.width}
          height={shape.height}
          image={shape.image}
          ref={shapeRef}
        />
      );}

      break;
    default:
      //square or rectangle
      SelectedShape = (
        <Rect
          {...commonProps}
          width={shape.width || (shape.type === ShapeTypes.square ? 30 : 60)}
          height={shape.height || 30}
          rotation={shape.rotation || undefined}
          ref={shapeRef}
        />
      );
  }

  return (
    <>
      {SelectedShape}
      {isSelected && !shape.cannotBeSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }

            return newBox;
          }}
        />
      )}
    </>
  );
};

WhiteboardShape.propTypes = {
  shape: PropTypes.object,
  isSelected: PropTypes.bool,
  avoidSelect: PropTypes.bool,
  onChange: PropTypes.func,
  defaultColor: PropTypes.string,
  handleSelectShape: PropTypes.func
};

export default WhiteboardShape;
