import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Stage, Layer, Rect } from "react-konva";
import "./style.less";
import WhiteboardShape from "./WhiteboardShape";
import {
  deleteShape,
  downloadContent,
  getCanvasMesures,
  getMenuBarType,
  getShapeTypeAndFont
} from "./domain";
import ColorIcon from "../../images/color_selection.svg";
import TextIcon from "../../images/text_selection.svg";
import ShapeIcon from "../../images/shape_selection.svg";
import PencilIcon from "../../images/pencil_selection.svg";
import StampIcon from "../../images/stamp_selection.svg";
import ImageIcon from "../../images/image_icon.svg";
import UploadImageIcon from "../../images/upload_image_icon.svg";
import MenuBar from "./MenuBar";
import {
  COLOR_ICONS,
  DEFAULT_SELECTED_OPTIONS,
  MENU_STATE,
  PAINT_OPTIONS,
  PENCIL_ICONS,
  PENCIL_OPTIONS,
  ShapeTypes,
  SHAPE_ICONS,
  STAMP_ICONS,
  STAMP_OPTIONS,
  TOOLBAR_COLOR,
  TOOLBAR_KEYS,
  WHITEBOARD_COLOR,
  STAMP_DATA,
  FONT_OPTIONS,
  PENCIL_CURSOR
} from "./constants";

const Whiteboard = ({ whiteboardReady, containerId, handleError }) => {
  const [CANVAS_WIDTH, CANVAS_HEIGHT] = getCanvasMesures(containerId);

  const [shapes, setShapes] = useState([]);
  const [history, setHistory] = useState([]);
  const [historyStep, setHistoryStep] = useState(-1);
  const [selectedShapeId, setSelectedShapeId] = useState();
  const [idIndex, setIdIndex] = useState(1);
  const [displayMenu, setDisplayMenu] = useState({});
  const [drawingState, setDrawingState] = useState(false);
  const [addingElementState, setAddingElementState] = useState(false);
  const [fontOptions, setFontOptions] = useState({
    [MENU_STATE.text]: {},
    [MENU_STATE.draw]: { pencil: FONT_OPTIONS.default },
    [MENU_STATE.shape]: { paint: PAINT_OPTIONS.stroke }
  });
  const [selectedOptions, setSelectedOptions] = useState(
    DEFAULT_SELECTED_OPTIONS
  );

  const isDrawing = useRef(false);
  const stageRef = useRef(null);
  const fileInputRef = useRef(null);
  const layerRef = useRef(null);
  const $container = useRef(null);

  useEffect(() => {
    const canvas = layerRef.current.getCanvas()._canvas;

    canvas.id = "shared_canvas";

    whiteboardReady && whiteboardReady(canvas);
  }, []);

  useEffect(() => {
    if (!selectedShapeId) return;

    const selectedShape = getSelectedShape();

    if (drawingState && selectedShape.type !== ShapeTypes.pen)
      setDrawingState(false);

    let [type, options] = getShapeTypeAndFont(selectedShape);

    setFontOptions({
      ...fontOptions,
      [type]: options
    });
  }, [selectedShapeId]);

  const updateShapes = (shapesList, selectLast) => {
    setShapes(shapesList);
    setHistory([...history, shapesList]);
    setHistoryStep(historyStep + 1);

    if (selectLast) setSelectedShapeId(shapesList[shapesList.length - 1].id);
  };

  const handleDownload = () => {
    if (selectedShapeId) setSelectedShapeId(null);

    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      downloadContent(uri, "valera_whiteboard");
    }, 10);
  };

  const getSelectedShape = () => {
    return shapes.find((_s) => _s.id === selectedShapeId);
  };

  const handleFlip = (up) => {
    if (!selectedShapeId) return;

    const items = shapes.slice();
    const item = items.find((i) => i.id === selectedShapeId);
    const index = items.indexOf(item);
    items.splice(index, 1);

    if (up) items.push(item);
    else items.unshift(item);

    updateShapes(items);
  };

  const changeShapeColor = (color) => {
    if (!selectedShapeId) return;

    const list = shapes.map((s) => {
      if (s.id !== selectedShapeId) return s;

      const n = {
        ...s,
        color: WHITEBOARD_COLOR[color]
      };

      return n;
    });

    updateShapes(list);
  };

  const handleChangeFont = (options) => {
    if (!selectedShapeId && !drawingState) return;

    let type;
    const list = shapes.map((s) => {
      if (s.id !== selectedShapeId) return s;

      type = getMenuBarType(s.type);

      const n = {
        ...s,
        fontOptions: options[type]
      };

      return n;
    });

    updateShapes(list);
    setFontOptions(options);
  };

  const getMenuBarState = () => {
    if (drawingState) return MENU_STATE.draw;
    if (!selectedShapeId) return MENU_STATE.cursor;

    const selectedShape = getSelectedShape();

    if (!selectedShape) return MENU_STATE.cursor;

    switch (selectedShape.type) {
      case ShapeTypes.text:
        return MENU_STATE.text;

      case ShapeTypes.pen:
        return MENU_STATE.draw;

      case ShapeTypes.stamp:
        return MENU_STATE.stamp;

      case ShapeTypes.image:
        return MENU_STATE.image;

      default:
        return MENU_STATE.shape;
    }
  };

  const undo = () => {
    if (!historyStep) {
      setShapes([]);
      return;
    }

    setShapes(history[historyStep - 1]);
    setHistoryStep(historyStep - 1);
  };

  const redo = () => {
    if (historyStep === history.length) return;

    setShapes(history[historyStep]);
    setHistoryStep(historyStep + 1);
  };

  const startDrawing = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();

    setShapes([
      ...shapes,
      {
        id: idIndex,
        type: "pen",
        tool: "pen",
        points: [pos.x, pos.y],
        color: WHITEBOARD_COLOR[selectedOptions.color],
        opacity: selectedOptions.pencil !== PENCIL_OPTIONS.opacity ? 1 : 0.5,
        erase: selectedOptions.pencil !== PENCIL_OPTIONS.erase ? false : true,
        drawing: true,
        cannotBeSelected: true,
        fontOptions: (fontOptions && fontOptions[MENU_STATE.draw]) || undefined
      }
    ]);

    setSelectedShapeId(idIndex);
    setIdIndex(idIndex + 1);
  };

  const drawLine = (e) => {
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = { ...shapes[shapes.length - 1] };

    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    setShapes(
      shapes.map((_s, i) => {
        if (i !== shapes.length - 1) return _s;
        return lastLine;
      })
    );
  };

  const stopDrawing = () => {
    if (!isDrawing.current) return;

    isDrawing.current = false;
    const list = shapes.map((_s, i) => {
      if (i !== shapes.length - 1) return _s;
      return {
        ..._s,
        drawing: false
      };
    });

    updateShapes(list);
    setSelectedShapeId(null);
  };

  const handleMouseDown = (e) => {
    checkDeselect(e);

    if (drawingState) startDrawing(e);
  };

  const handleMouseMove = (e) => {
    if (isDrawing.current) drawLine(e);
  };

  const handleMouseUp = () => {
    stopDrawing();
  };

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty =
      e.target === e.target.getStage() ||
      e.target.getId() === "background-rect";

    if (clickedOnEmpty) {
      if (selectedShapeId) {
        setSelectedShapeId(null);

        const shape = getSelectedShape();
        if (shape.type === ShapeTypes.text) {
          const items = shapes.map((_s) => {
            if (_s.id !== selectedShapeId) return _s;

            return {
              ..._s,
              isEditing: false,
              cannotBeSelected: false
            };
          });

          updateShapes(items);
        }
      }
    }
  };

  const addShape = (type, position, data) => {
    let _shape;
    if (
      [ShapeTypes.stamp, ShapeTypes.text, ShapeTypes.image].indexOf(type) === -1
    ) {
      _shape = {
        id: idIndex,
        type: type,
        color: WHITEBOARD_COLOR[selectedOptions.color],
        fontOptions: fontOptions.Shape || {
          paint: PAINT_OPTIONS.stroke
        }
      };
    } else if (type === ShapeTypes.text) {
      _shape = {
        id: idIndex,
        type: type,
        color: WHITEBOARD_COLOR[selectedOptions.color],
        text: "Sample text",
        fontOptions: fontOptions.Text,
        height: 40,
        width: 200
      };
    } else if (type === ShapeTypes.stamp) {
      _shape = {
        id: idIndex,
        type: type,
        draggable: true,
        data: STAMP_DATA[data]
      };
    } else if (type === ShapeTypes.image) {
      _shape = {
        id: idIndex,
        type,
        draggable: true,
        image: data
      };
    }

    if (position) {
      Object.assign(_shape, position);
    }

    setIdIndex(idIndex + 1);
    updateShapes([...shapes, _shape], type !== ShapeTypes.image);
  };

  const uploadFile = (event) => {
    if (!event || !event.target && !event.target.files) return;
  
    const file = event.target.files[0];
    console.log(file);
    if(["image/png", "image/jpeg", "image/svg+xml"].indexOf(file.type) === -1) {
      handleError && handleError("File format not supported");
      return;
    }
  
    const content = URL.createObjectURL(file);
    const shape_image = new Image();
    shape_image.src = content;
    addShape(
      ShapeTypes.image,
      { x: CANVAS_WIDTH / 2, y: CANVAS_HEIGHT / 2 },
      shape_image
    );
  }

  const toolbarList = [
    {
      key: TOOLBAR_KEYS.color,
      icon: <ColorIcon />,
      tooltip: "Select color",
      openedElement: (
        <div className="menu-element">
          <div className="menu-element-content">
            {[
              TOOLBAR_COLOR.orange,
              TOOLBAR_COLOR.green,
              TOOLBAR_COLOR.blue,
              TOOLBAR_COLOR.red,
              TOOLBAR_COLOR.gray,
              TOOLBAR_COLOR.purple
            ].map((color, i) => {
              return (
                <span
                  key={i}
                  className={`change-color-element pointer ${
                    color === selectedOptions.color ? "selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedOptions({ ...selectedOptions, color });
                    changeShapeColor(color);
                  }}
                >
                  {COLOR_ICONS[color]}
                </span>
              );
            })}
          </div>
        </div>
      )
    },
    {
      key: TOOLBAR_KEYS.text,
      icon: <TextIcon />,
      tooltip: "Add text",
      onclick: () => {
        if (addingElementState) {
          setAddingElementState(false);
        } else {
          setAddingElementState(ShapeTypes.text);
        }
      }
    },
    {
      key: TOOLBAR_KEYS.pencil,
      icon: <PencilIcon />,
      onclick: () => {
        setDrawingState(!drawingState);
      },
      openedElement: (
        <div className="menu-element">
          <div className="menu-element-content">
            {[
              PENCIL_OPTIONS.normal,
              PENCIL_OPTIONS.opacity,
              PENCIL_OPTIONS.erase
            ].map((pencil, i) => {
              return (
                <span
                  key={i}
                  className={`change-pencil-element pointer ${
                    pencil === selectedOptions.pencil ? "selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedOptions({ ...selectedOptions, pencil });
                    setDrawingState(true);
                  }}
                >
                  {" "}
                  {PENCIL_ICONS[pencil]}{" "}
                </span>
              );
            })}
          </div>
        </div>
      )
    },
    {
      key: TOOLBAR_KEYS.shape,
      icon: <ShapeIcon />,
      border: true,
      onclick: () => {
        if (addingElementState) {
          setAddingElementState(false);
        }
      },
      openedElement: (
        <div className="menu-element">
          <div className="menu-element-content">
            {[
              ShapeTypes.triangle,
              ShapeTypes.circle,
              ShapeTypes.pentagone,
              ShapeTypes.square,
              ShapeTypes.rect,
              ShapeTypes.hexagon
            ].map((menuShape, i) => {
              return (
                <span
                  key={i}
                  className={`change-shape-element ${menuShape} ${
                    menuShape === selectedOptions.shape ? "selected" : ""
                  } ${addingElementState ? "click-plus" : ""}`}
                  onKeyDown={() => {
                    setSelectedOptions({
                      ...selectedOptions,
                      shape: menuShape
                    });
                  }}
                  onClick={() => {
                    if (drawingState) setDrawingState(false);

                    setSelectedOptions({
                      ...selectedOptions,
                      shape: menuShape
                    });

                    if (!addingElementState) {
                      setAddingElementState("shape");
                    } else {
                      setAddingElementState(false);
                    }
                  }}
                >
                  {SHAPE_ICONS[menuShape]}
                </span>
              );
            })}
          </div>
        </div>
      )
    },
    {
      key: TOOLBAR_KEYS.stamp,
      icon: <StampIcon />,
      openedElement: (
        <div className="menu-element">
          <div className="menu-element-content">
            {Object.keys(STAMP_OPTIONS).map((stamp, i) => {
              return (
                <span
                  key={i}
                  className={`change-pencil-element pointer ${
                    stamp === selectedOptions.stamp ? "selected" : ""
                  }`}
                  draggable
                  onDragStart={() => {
                    setSelectedOptions({ ...selectedOptions, stamp });
                  }}
                >
                  {" "}
                  {STAMP_ICONS[stamp]}{" "}
                </span>
              );
            })}
          </div>
        </div>
      )
    },
    {
      key: TOOLBAR_KEYS.image,
      icon: <ImageIcon />,
      openedElement: (
        <div className="menu-element">
            <div
              className="upload-image"
              onClick={() => {
                fileInputRef.current.click();
              }}
            >
              <UploadImageIcon />
              <span>Upload jpeg/png</span>
          </div>
        </div>
      )
    }
  ];

  return (
    <div
      className="whiteboard_container"
      id="whiteboard_container_1"
      ref={$container}
    >
      <MenuBar
        activeUndo={historyStep > -1}
        activeRedo={historyStep > -1 && historyStep !== history.length - 1}
        activeClearAll={shapes.length !== 0}
        state={getMenuBarState()}
        handleDeleteSelected={() => {
          const list = deleteShape(shapes, selectedShapeId);
          updateShapes(list);
        }}
        handleFlip={handleFlip}
        handleChangeFont={handleChangeFont}
        fontOptions={fontOptions}
        handleUndo={undo}
        handleRedo={redo}
        handleClearAll={() => {
          updateShapes([]);
        }}
        handleDownload={handleDownload}
      />
      <div className="toolbar">
        {toolbarList.map((toolbarElement, i) => {
          return (
            <>
              <div
                className="toolbar-menu"
                title={toolbarElement.tooltip}
                key={i}
                onMouseEnter={() => {
                  setDisplayMenu({
                    [toolbarElement.key]: true
                  });
                }}
                onMouseLeave={() => {
                  setDisplayMenu({
                    [toolbarElement.key]: false
                  });
                }}
              >
                <span
                  className={`toolbar-menu-icon pointer ${
                    toolbarElement.key === TOOLBAR_KEYS.pencil && drawingState
                      ? "active"
                      : ""
                  } ${
                    ~[
                      TOOLBAR_KEYS.color,
                      TOOLBAR_KEYS.text,
                      TOOLBAR_KEYS.pencil,
                      TOOLBAR_KEYS.shape
                    ].indexOf(toolbarElement.key)
                      ? selectedOptions.color
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      drawingState &&
                      ~[TOOLBAR_KEYS.shape, TOOLBAR_KEYS.text].indexOf(
                        toolbarElement.key
                      )
                    )
                      setDrawingState(false);

                    toolbarElement.onclick && toolbarElement.onclick();
                  }}
                >
                  <span
                    className={
                      ~[TOOLBAR_KEYS.color].indexOf(toolbarElement.key)
                        ? "colored"
                        : ""
                    }
                  >
                    {toolbarElement.icon}
                  </span>
                </span>
                {displayMenu[toolbarElement.key] &&
                  toolbarElement.openedElement}
              </div>
              {toolbarElement.border && <span className="tool-border"></span>}
            </>
          );
        })}
      </div>
      <div
        className={
          "canvas-container " +
          (addingElementState ? "click-plus" : "") +
          (drawingState ? PENCIL_CURSOR[selectedOptions.pencil] : "")
        }
        onClick={(e) => {
          if (!addingElementState) return;

          if (addingElementState === "shape") {
            e.preventDefault();
            stageRef.current.setPointersPositions(e);
            addShape(
              selectedOptions.shape,
              stageRef.current.getPointerPosition()
            );

            setAddingElementState(false);
          } else if (addingElementState === ShapeTypes.text) {
            e.preventDefault();
            stageRef.current.setPointersPositions(e);
            addShape(ShapeTypes.text, stageRef.current.getPointerPosition());

            setAddingElementState(false);
          }
        }}
        onDrop={(e) => {
          e.preventDefault();
          stageRef.current.setPointersPositions(e);

          addShape(
            ShapeTypes.stamp,
            stageRef.current.getPointerPosition(),
            selectedOptions.stamp
          );
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        <Stage
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
          onTouchStart={checkDeselect}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          ref={stageRef}
        >
          <Layer ref={layerRef}>
            <Rect
              width={CANVAS_WIDTH}
              height={CANVAS_HEIGHT}
              fill="white"
              id="background-rect"
            ></Rect>
            {shapes.map((shape, i) => {
              return (
                <WhiteboardShape
                  defaultColor={selectedOptions.color}
                  avoidSelect={drawingState}
                  handleSelectShape={() => {
                    if (drawingState) return;

                    setSelectedShapeId(shape.id);
                  }}
                  shape={shape}
                  isSelected={shape.id === selectedShapeId}
                  onChange={(newAttrs) => {
                    if (newAttrs.isTransforming !== shape.isTransforming) {
                      setSelectedShapeId(shape.id);
                    }

                    const list = shapes.slice();
                    list[i] = newAttrs;
                    updateShapes(list);
                  }}
                  key={i}
                ></WhiteboardShape>
              );
            })}
          </Layer>
        </Stage>
      </div>
      <input
        ref={fileInputRef}
        style={{ display: "none" }}
        type="file"
        onChange={uploadFile}
      />
    </div>
  );
};

Whiteboard.propTypes = {
  containerId: PropTypes.string,
  handleError: PropTypes.func,
  whiteboardReady: PropTypes.func,
};

export default Whiteboard;
