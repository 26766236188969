const upSticker = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="stamp-icon">
<g filter="url(#filter0_d_549_2160)">
<path d="M11.5563 41.3105L17.3518 39.7576L12.6931 22.371L6.89752 23.9239L11.5563 41.3105ZM39.1613 16.8317C38.7342 15.238 37.0808 14.2834 35.4871 14.7104L26.3446 17.1601L25.9468 10.1699L25.866 9.69461C25.7069 9.10056 25.313 8.61598 24.817 8.3296L22.8736 7.21979L15.8983 19.3225C15.502 19.9878 15.3773 20.7976 15.5908 21.5945L19.4731 36.0834C19.9002 37.6772 21.5536 38.6318 23.1474 38.2047L36.1874 34.7107C37.3899 34.3884 38.2245 33.3883 38.3797 32.2287L40.0183 20.8416C40.0594 20.4734 40.0387 20.1062 39.9378 19.7295L39.1613 16.8317Z" fill="#57B9E5"/>
<path d="M11.5563 41.3105L17.3518 39.7576L12.6931 22.371L6.89752 23.9239L11.5563 41.3105ZM39.1613 16.8317C38.7342 15.238 37.0808 14.2834 35.4871 14.7104L26.3446 17.1601L25.9468 10.1699L25.866 9.69461C25.7069 9.10056 25.313 8.61598 24.817 8.3296L22.8736 7.21979L15.8983 19.3225C15.502 19.9878 15.3773 20.7976 15.5908 21.5945L19.4731 36.0834C19.9002 37.6772 21.5536 38.6318 23.1474 38.2047L36.1874 34.7107C37.3899 34.3884 38.2245 33.3883 38.3797 32.2287L40.0183 20.8416C40.0594 20.4734 40.0387 20.1062 39.9378 19.7295L39.1613 16.8317Z" stroke="white"/>
</g>
<defs>
<filter id="filter0_d_549_2160" x="4.28516" y="6.53864" width="36.2531" height="37.3842" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-1" dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_549_2160"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_549_2160" result="shape"/>
</filter>
</defs>
</svg>`;

export default upSticker;