import React from "react";
import Popup from "./common/Popup";
import { useSelector } from "react-redux";

const InvalidPage = () => {
  const {title, message} = useSelector(state => state.global);
  return (
    <Popup hideCloseBtn={true} title={title}>
      {message}
    </Popup>
  );
};

export default InvalidPage;
