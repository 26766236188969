import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import TrashIcon from "../../images/trash_menu.svg";
import FlipBackIcon from "../../images/flip_back.svg";
import FlipFrontIcon from "../../images/flip_front.svg";
import BoldIcon from "../../images/format_bold.svg";
import UnderlineIcon from "../../images/format_underlined.svg";
import LeftAlignIcon from "../../images/format_align_left.svg";
import RightAlignIcon from "../../images/format_align_right.svg";
import CenterAlignIcon from "../../images/format_align_center.svg";
import FillIcon from "../../images/filled_bar.svg";
import StrokeIcon from "../../images/outline_bar.svg";
import BigPencilIcon from "../../images/big_pencil.svg";
import DefaultPencilIcon from "../../images/default_pencil.svg";
import SmallPencilIcon from "../../images/small_pencil.svg";
import UndoIcon from "../../images/undo_history.svg";
import RedoIcon from "../../images/redo_history.svg";
import DownloadIcon from "../../images/download.svg";
import ClearIcon from "../../images/clear.svg";
import {
  ALIGN_OPTIONS,
  DEFAULT_FONT_SIZE,
  FONT_OPTIONS,
  FONT_SIZE_VALUES,
  MENU_STATE,
  PAINT_OPTIONS,
} from "./constants";

const MenuBar = ({
  activeUndo,
  activeRedo,
  activeClearAll,
  state,
  handleDeleteSelected,
  handleFlip,
  handleChangeFont,
  handleUndo,
  handleRedo,
  handleClearAll,
  handleDownload,
  fontOptions,
}) => {
  const updateFont = (key, value) => {
    const temp = Object.assign({}, fontOptions);
    if (temp[state][key] === value) return;

    temp[state] = {
      ...temp[state],
      [key]: value,
    };

    handleChangeFont(temp);
  };

  const getFontArea = () => {
    switch (state) {
      case MENU_STATE.text:
        return (
          <>
            <select
              onChange={(e) => {
                updateFont("fontSize", parseInt(e.target.value));
              }}
              style={{ marginRight: "12px" }}
              defaultValue={fontOptions[state].fontSize || DEFAULT_FONT_SIZE}
            >
              {FONT_SIZE_VALUES.map((val, index) => {
                return <option key={index} value={val}>{`${val} px`}</option>;
              })}
            </select>
            <span
              className={`button pointer ${
                fontOptions[state].bold ? "active" : ""
              }`}
              onClick={(e) => {
                updateFont("bold", !fontOptions[state].bold);
              }}
            >
              <BoldIcon />
            </span>
            <span
              style={{ marginRight: "12px" }}
              className={`button pointer ${
                fontOptions[state].underline ? "active" : ""
              }`}
              onClick={(e) => {
                updateFont("underline", !fontOptions[state].underline);
              }}
            >
              <UnderlineIcon />
            </span>
            <span
              className={`button pointer ${
                fontOptions[state].align === ALIGN_OPTIONS.left ? "active" : ""
              }`}
              onClick={(e) => {
                updateFont("align", ALIGN_OPTIONS.left);
              }}
            >
              <LeftAlignIcon />
            </span>
            <span
              className={`button pointer ${
                fontOptions[state].align === ALIGN_OPTIONS.center
                  ? "active"
                  : ""
              }`}
              onClick={(e) => {
                updateFont("align", ALIGN_OPTIONS.center);
              }}
            >
              <CenterAlignIcon />
            </span>
            <span
              className={`button pointer ${
                fontOptions[state].align === ALIGN_OPTIONS.right ? "active" : ""
              }`}
              onClick={(e) => {
                updateFont("align", ALIGN_OPTIONS.right);
              }}
            >
              <RightAlignIcon />
            </span>
          </>
        );

      case MENU_STATE.shape:
        return (
          <>
            <span
              className={`button pointer ${
                fontOptions[state].paint === PAINT_OPTIONS.stroke
                  ? "active"
                  : ""
              }`}
              onClick={(e) => {
                updateFont("paint", PAINT_OPTIONS.stroke);
              }}
            >
              <StrokeIcon />
            </span>
            <span
              className={`button pointer ${
                fontOptions[state].paint === PAINT_OPTIONS.fill ? "active" : ""
              }`}
              onClick={(e) => {
                updateFont("paint", PAINT_OPTIONS.fill);
              }}
            >
              <FillIcon />
            </span>
          </>
        );

      case MENU_STATE.draw:
        return (
          <>
            <span
              className={`button pointer ${
                fontOptions[state].pencil === FONT_OPTIONS.small ? "active" : ""
              }`}
              onClick={(e) => {
                updateFont("pencil", FONT_OPTIONS.small);
              }}
            >
              <SmallPencilIcon />
            </span>
            <span
              className={`button pointer ${
                fontOptions[state].pencil === FONT_OPTIONS.default
                  ? "active"
                  : ""
              }`}
              onClick={(e) => {
                updateFont("pencil", FONT_OPTIONS.default);
              }}
            >
              <DefaultPencilIcon />
            </span>
            <span
              className={`button pointer ${
                fontOptions[state].pencil === FONT_OPTIONS.big ? "active" : ""
              }`}
              onClick={(e) => {
                updateFont("pencil", FONT_OPTIONS.big);
              }}
            >
              <BigPencilIcon />
            </span>
          </>
        );
      default:
        return <span></span>;
    }
  };

  return (
    <div className="menu-bar">
      <div className="title inactive">{state}</div>
      {state !== MENU_STATE.cursor && (
        <>
          <div className="trash-and-superpose">
            <span
              className="icon pointer"
              style={{ marginRight: "15px" }}
              onClick={handleDeleteSelected}
            >
              <TrashIcon />
            </span>
            <span
              className="icon pointer"
              onClick={() => {
                handleFlip(false);
              }}
            >
              <FlipBackIcon />
            </span>
            <span
              className="icon pointer"
              onClick={() => {
                handleFlip(true);
              }}
            >
              <FlipFrontIcon />
            </span>
          </div>
          <div className="font" style={{ marginLeft: "22px" }}>
            {getFontArea()}
          </div>
        </>
      )}
      <div className="history">
        <span
          className={activeUndo ? "pointer" : "inactive"}
          onClick={handleUndo}
        >
          <UndoIcon />
        </span>
        <span
          className={activeRedo ? "pointer" : "inactive"}
          onClick={handleRedo}
        >
          <RedoIcon />
        </span>
      </div>
      <div className={`clear pointer ${activeClearAll ? "" : "inactive"}`}>
        <ClearIcon />
        <span style={{fontSize: "11px"}} onClick={handleClearAll}>Clear all</span>
      </div>
      <div className="download pointer">
        <DownloadIcon />
        <span onClick={handleDownload}>Download</span>
      </div>
    </div>
  );
};

MenuBar.propTypes = {
  activeUndo: PropTypes.bool,
  activeRedo: PropTypes.bool,
  activeClearAll: PropTypes.bool,
  state: PropTypes.string,
  fontOptions: PropTypes.object,
  handleDeleteSelected: PropTypes.func,
  handleFlip: PropTypes.func,
  handleChangeFont: PropTypes.func,
  handleUndo: PropTypes.func,
  handleRedo: PropTypes.func,
  handleClearAll: PropTypes.func,
  handleDownload: PropTypes.func
};

export default MenuBar;
