import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function InputSourcesList({
  input = "audioInput",
  selectedSource,
  handleChange,
}) {
  const OT = window.OT;
  const [inputSources, setInputSources] = useState([]);
  const [initialSource, setInitialSource] = useState();

  const checkPermission = () => {
    navigator.permissions
      .query({ name: input === "audioInput" ? "microphone" : "camera" })
      .then(function (result) {
        if (result.state !== "granted") {
          setTimeout(checkPermission, 3000);
        } else {
          OT.getDevices((err, devices) => {
            setInputSources(
              devices
                .filter((d) => d.kind === input)
                .map((s, index) => {
                  if (!index) {
                    setInitialSource(s.deviceId);
                  }

                  return {
                    key: s.deviceId,
                    value: s.label,
                  };
                })
            );
          });
        }
      });
  };

  useEffect(() => {
    //need to know what is selected audio or video even if it has not been changed (for settings);
    handleChange(initialSource);
  }, [initialSource]);

  useEffect(() => {
    checkPermission();
  }, []);

  return (
    <select
      className="titleAndSelect-select"
      selected={selectedSource}
      onChange={(event) => {
        handleChange(event.target.value);
      }}
      onLoad={(event) => {
        handleChange(event.target.value);
      }}
    >
      {inputSources.map((o, index) => {
        return (
          <option key={index} value={o.key}>
            {o.value}
          </option>
        );
      })}
    </select>
  );
}

InputSourcesList.propTypes = {
  input: PropTypes.string,
  selectedSource: PropTypes.string,
  handleChange: PropTypes.func,
};
