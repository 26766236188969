/*eslint no-unused-vars: "off"*/
import React from "react";
import MessageItem from "./messageItem";
import { useSelector, useDispatch } from "react-redux";
import "./style.less";
import { deleteUserMessage } from "../../../redux/slices/globalSlice";
/**
 * Every item has {type: "{success/warning}", text: "..."}
 * */
const UserMessages = () => {  
  const {messageItems} = useSelector(state => state.global.userMessageArray);
  const dispatch = useDispatch();
  
  if (!messageItems || !messageItems.length) {
    return <div />;
  }
  return (
    <div className="user-message-pipe">
      {messageItems.length &&
        messageItems.map((item) => (
          <MessageItem
            type={item.type}
            text={item.text}
            key={item.id}
            deleteItem={() => {
              item.onClosed && item.onClosed();
              dispatch(deleteUserMessage(item.id));
            }}
          />
        ))}
    </div>
  );
};

export default UserMessages;
