const heartSticker = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="stamp-icon">
<g filter="url(#filter0_d_563_1687)">
<path d="M27.6753 37.275L25.062 35.9254C15.7872 31.1585 9.6652 28.0159 8.1977 22.5391C7.00195 18.0765 9.56875 13.6307 14.0313 12.4349C16.5524 11.7594 19.2865 12.2847 21.3627 13.7161C22.4451 11.4383 24.5503 9.61639 27.0713 8.94088C31.5339 7.74513 35.9797 10.3119 37.1755 14.7745C38.643 20.2513 34.9125 26.0339 29.2676 34.814L27.6753 37.275Z" fill="#FF0000"/>
<path d="M27.6753 37.275L25.062 35.9254C15.7872 31.1585 9.6652 28.0159 8.1977 22.5391C7.00195 18.0765 9.56875 13.6307 14.0313 12.4349C16.5524 11.7594 19.2865 12.2847 21.3627 13.7161C22.4451 11.4383 24.5503 9.61639 27.0713 8.94088C31.5339 7.74513 35.9797 10.3119 37.1755 14.7745C38.643 20.2513 34.9125 26.0339 29.2676 34.814L27.6753 37.275Z" stroke="white"/>
</g>
<defs>
<filter id="filter0_d_563_1687" x="5.4046" y="8.14783" width="32.5916" height="31.7795" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-1" dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_563_1687"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_563_1687" result="shape"/>
</filter>
</defs>
</svg>`;

export default heartSticker;