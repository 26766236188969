const OT = window.OT;

export default class Publisher {
  constructor({ element, settings, onAfterInit, onError }) {
    this.settings = getPublisherOptions(settings);
    this.element = element;
    this.onAfterInit = onAfterInit;
    this.onError = onError;
    this.init();

    window.Publisher = this;
  }

  createPublisher = ($container) => {
    return new Promise((resolve, reject) => {
      const p = OT.initPublisher(
        $container || this.element,
        this.settings,
        (err) => {
          if (err) {
            this.onError(err && err.code);
            return reject(err);
          }

          return resolve(p);
        }
      );
    });
  };

  async init($container) {
    this.afterDestroyed = this.settings.afterDestroyed;
    this.afterMediaStopped = this.settings.afterMediaStopped;
    this.data = await this.createPublisher($container);
    this.displayNameAndPicture();
    this.initialized = true;
    this.onAfterInit && this.onAfterInit();

    this.data.on({
      destroyed: (event) => {
        this.afterDestroyed && this.afterDestroyed(event);
      },
      mediaStopped: (event) => {
        this.afterMediaStopped && this.afterMediaStopped(event);
      },
      // streamDestroyed: (event) => {
      //   debugger;
      //   this.afterMediaStopped && this.afterMediaStopped(event);
      // },
      // streamDestroyed: (event) => {
      //   // console.log("The publisher stopped streaming. Reason: " + event.reason);
      //   //reason can be "clientDisconnected", "forceDisconnected", "forceUnpublished", or "networkDisconnected"
      // },
      // streamCreated: (event) => {
      //   //The Session object dispatches a streamCreated event when a new stream (other than your own) is created in a session
      //   // console.log("stream created");
      // },
      // accessAllowed: () => {},
      // accessDenied: () => {},
      // accessDialogOpened: (event) => {},
    });
  }

  changeVideoSource({videoSource, $container, unpublish, audioSource}) {
    if (videoSource === this.settings.videoSource) return;

    OT.getDevices((err, devices) => {
      const devicesCount = devices.filter((d) => d.kind === "videoInput")
        .length;
      // if (devicesCount < 2) return;
      // else {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: true })
          .then((stream) => {
            stream.getTracks().forEach((track) => track.stop());

            // if (devicesCount === 2)
            //   this.data
            //     .cycleVideo()
            //     .then((a, b, c) => {
            //       debugger;
            //     })
            //     .catch((a, b, c) => {
            //       debugger;
            //     });
            // else {
              unpublish();
              this.data && this.data.destroy();
              this.settings.videoSource = videoSource;

              if(audioSource) {
                this.settings.audioSource = audioSource
              }

              this.init($container);
            // }
          })
          .catch((err) => {});
      // }
    });
  }

  changeAudioSource(source) {
    this.data && this.data.setAudioSource(source);
  }

  publishVideo(p) {
    this.data && this.data.publishVideo(p);
    this.settings.publishVideo = p;
  }

  publishAudio(p) {
    this.data && this.data.publishAudio(p);
    this.settings.publishAudio = p;
  }

  enableMirror() {
    if(!this.settings || this.settings.mirror) return;

    this.settings.mirror = true;
  }

  disableMirror() {
    if(!this.settings || !this.settings.mirror) return;

    this.settings.mirror = false;
  }

  getVideoSource() {
    const videoSourceData = this.data.getVideoSource();
    return videoSourceData && videoSourceData.deviceId;
  }

  getAudioSource() {
    const audioSourceData = this.data.getAudioSource();
    return audioSourceData && audioSourceData.id;
  }

  displayNameAndPicture() {
    try {
      if (this.settings.participantName) {
        const $name = this.element.querySelector(".OT_name");
        $name.classList.remove("OT_edge-bar-item", "OT_mode-off");
        $name.innerHTML = this.settings.participantName;
      }

      if (this.settings.participantPicture) {
        this.element.querySelector(".OT_video-poster").style.backgroundImage =
          "url(" + this.settings.participantPicture + ")";
      }
    } catch (error) {
      //silent
    }
  }
}

export const getPublisherOptions = (settings) => {
  return {
    insertMode: settings.insertMode || "append",
    width: settings.width || "100%",
    height: settings.height || "100%",
    resolution: settings.resolution || "1280x720", //"640x480" or 320x240
    frameRate: settings.frameRate || 30, //15, 7, or 1
    showControls: true, //settings.showControls || false,
    audioSource: settings.audioSource,
    videoSource: settings.videoSource,
    publishAudio: settings.publishAudio,
    publishVideo: settings.publishVideo,
    style: {
      buttonDisplayMode: "off",
      videoDisabledDisplayMode: "off",
      audioLevelDisplayMode: "off",
    },
    participantName: settings.name,
    participantPicture: settings.picture,
    mirror: typeof(settings.mirror) !== "undefined" ? settings.mirror : true,
    afterDestroyed: settings.afterDestroyed,
    afterMediaStopped: settings.afterMediaStopped,
  };
};
