import React from "react";
import Modal from "react-modal";
import Button from "./Button";
import IconButton from "./IconButton";
import "./css/popup.css";
import PropTypes from "prop-types";
import { isIOS } from "../utilities/index";

export default function Popup({
  title,
  buttons,
  onClosed,
  hideCloseBtn,
  children,
  bodyClass,
  options = {}
}) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      borderRadius: "5px",
      minWidth: "350px"
    },
    overlay: {
      background: "initial",
      zIndex: 2
    }
  };

  if (isIOS) {
    customStyles.content.fontFamily = "Avenir";
    customStyles.content.fontSize = "13px";
  } else {
    customStyles.content.fontFamily = "Verdana";
    customStyles.content.fontSize = "12px";
  }

  return (
    <div>
      <Modal
        isOpen={true}
        onAfterClose={onClosed}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="popup-header">
          <div className="popup-header-content">{title}</div>
          <div className="popup-header-icon">
            {!hideCloseBtn && (
              <IconButton
                onClick={() => {
                  onClosed();
                }}
                type="close"
              />
            )}
          </div>
        </div>
        <div
          className={
            (bodyClass || "popup-body") +
            (isIOS ? " iosDevice" : " androidDevice")
          }
        >
          {children}
        </div>
        {!options.hideFooter && (
          <div className="popup-footer">
            {buttons &&
              buttons.map((b, index) => {
                if (!b.component) {
                  if (!b.invisible)
                    return (
                      <Button
                        key={index}
                        type={b.type || "primary"}
                        {...b}
                      ></Button>
                    );

                  return <span style={{ display: "none" }}></span>;
                } else return b.component;
              })}
          </div>
        )}
      </Modal>
    </div>
  );
}

Popup.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array,
  type: PropTypes.string,
  onClosed: PropTypes.func,
  hideCloseBtn: PropTypes.bool,
  bodyClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  options: PropTypes.object
};
