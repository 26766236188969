import { handleResponse, handleError } from "./apiUtils";
import { getToken } from "./storageConfig";

const baseUrl = process.env.API_URL;

export const getChatMessages = (sessionId, roomId) => {
  return fetch(
    baseUrl + "/room/" + roomId + "/session/" + sessionId + "/chat",
    {
      method: "GET",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
      },
    }
  )
    .then(handleResponse)
    .catch(handleError);
};

export const sendChatMessage = (sessionId, roomId, content) => {
  return fetch(
    baseUrl + "/room/" + roomId + "/session/" + sessionId + "/chat",
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ body: content }),
    }
  )
    .then(handleResponse)
    .catch(handleError);
};

export const setAsMessageRead = (sessionId, roomId) => {
  return fetch(
    baseUrl + "/room/" + roomId + "/session/" + sessionId + "/chat/read",
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
      },
    }
  )
    .then(handleResponse)
    .catch(handleError);
};
