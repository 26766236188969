const checkSticker = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="stamp-icon">
<g filter="url(#filter0_d_563_1695)">
<path d="M22.2391 36.857L10.1666 29.887L13.2525 24.8451L20.0761 28.7846L31.2888 9.3637L36.5377 12.3942L22.2391 36.857Z" fill="#0EBF7F"/>
<path d="M22.2391 36.857L10.1666 29.887L13.2525 24.8451L20.0761 28.7846L31.2888 9.3637L36.5377 12.3942L22.2391 36.857Z" stroke="white" stroke-width="1.42857"/>
</g>
<defs>
<filter id="filter0_d_563_1695" x="7.1748" y="8.38794" width="30.3419" height="31.4429" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-1" dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_563_1695"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_563_1695" result="shape"/>
</filter>
</defs>
</svg>`;

export default checkSticker;