import { handleResponse, handleError } from "./apiUtils";
import {
  getToken,
  setSessionToken,
  setOpenTokSessionId,
  setApiKey
} from "./storageConfig";

const baseUrl = process.env.API_URL;

export const loadRoomSettings = (roomId, sessionId) => {
  return fetch(
    baseUrl + "/enter",
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({sessionId, roomId})
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return response.json().then((res) => {
          setApiKey(res.apiKey);
          setOpenTokSessionId(res.openTokSessionId);
          setSessionToken(res.token);
          return res;
        });
      }
      if (~[400, 401].indexOf(response.status)) {
        return response.json().then((r) => {
          return {
            error: r.message
          };
        });
      }
    })
    .catch(handleError);
};

export const checkPhoneLogin = (phone, roomId, type = "sms") => {
  return fetch(baseUrl + "/auth?type=" + type, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      phone,
      roomId
    })
  }).then((response) => {
    if (response.status === 200 || response.status === 204) return true;
    return false;
  });
};

export const checkCodeLogin = (phone, code, roomId) => {
  return fetch(baseUrl + "/auth/verify", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      phone,
      code,
      roomId
    })
  })
    .then(handleResponse)
    .catch(handleError);
};

export const getWaitingRoom = (sessionId, roomId) => {
  return fetch(
    baseUrl + "/room/" + roomId + "/session/" + sessionId + "/waiting",
    {
      method: "GET",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json"
      }
    }
  )
    .then((response) => {
      return response;
    })
    .catch(handleError);
};

export const callParticipant = (sessionId, roomId, patientId) => {
  return fetch(
    baseUrl + "/ring",
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({sessionId, roomId, patientId})
    }
  );
};

export const callMissed = (sessionId, roomId, patientId) => {
  return fetch(
    baseUrl + "/hangup",
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({sessionId, roomId, patientId})
    }
  );
};

export const sendEmailVideoRequest = (roomId, email) => {
  return fetch(
    baseUrl + "/sendemail",
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        roomId,
        email
      })
    }
  );
};

export const getFeedbackConfiguration = () => {
  return fetch(
    baseUrl + "/feedback/config",
    {
      method: "GET",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json"
      }
    }
  );
};

export const sendFeedback = (rating, tags) => {
  return fetch(
    baseUrl + "/feedback",
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        source: "web",
        rating,
        tags
      })
    }
  );
};


