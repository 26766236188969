import React from "react";
import IconButton from "../../common/IconButton";
import SuccessIcon from "../../../images/icon_success.svg";
import WarningIcon from "../../../images/icon_warning.svg";
import PropTypes from "prop-types";
/**
 * Every item has {type: "{success/warning}", text: "..."}
 * */
const messageItem = ({ type, text, key, deleteItem }) => {
  const className =
    type === "success"
      ? "user-message-item success-message"
      : "user-message-item warning-message";
  const typeText = type;
  const IconMessage = () => {
    if (type === "success") {
      return <SuccessIcon />;
    } else {
      return <WarningIcon />;
    }
  };
  let textShowArray = text instanceof Array ? text : [text];
  return (
    <div className={className} key={key}>
      <div className="message-icon">
        <IconMessage />
      </div>
      <div className="message-body">
        <div className="message-title">{typeText}</div>
        {textShowArray.map((textItem, index) => {
          return (
            <div key={"user-message-key-index-" + index}>
              {textItem}
              <br />
            </div>
          );
        })}
      </div>
      <div className="close-icon">
        <IconButton onClick={deleteItem} type="close" />
      </div>
    </div>
  );
};

messageItem.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  key: PropTypes.number,
  deleteItem: PropTypes.func,
};

export default messageItem;
