import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkPhoneLogin } from "../../api/videoAPI";
import { checkCodeLogin } from "../../redux/slices/videoSlice";
import { useHistory } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import LoginCode from "./LoginCode";
import LoginPhone from "./LoginPhone";
import { displayErrorPage } from "../../redux/slices/globalSlice";

const LoginPage = () => {
  const dispatch = useDispatch();
  const {roomId} = useSelector(state => state.video);
  const history = useHistory();

  const [isPhoneCorrect, setIsPhoneCorrect] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState(false);
  const [formattedNumber, setFormattedNumber] = useState(false);
  const [checkingPhone, setCheckingPhone] = useState(false);
  const [sendType, setSendType] = useState("sms"); //sms, call

  useEffect(() => {
    if (formattedNumber === false) return;
    if (formattedNumber === "invalid") setPhoneErrorMessage(true);
    else {
      setPhoneErrorMessage(false);

      checkPhoneLogin(formattedNumber.number, roomId, sendType)
        .then((success) => {
          setCheckingPhone(false);

          if (success) {
            setIsPhoneCorrect(true);
          } else {
            dispatch(displayErrorPage());
          }
        })
        .catch(() => {
          dispatch(displayErrorPage());
          //TODO: error => 500 something went, network error: please check internet connection
        });
    }
  }, [formattedNumber]);

  const checkPhone = (phone) => {
    if (!phone || !phone.length) {
      if (userPhone) {
        phone = userPhone;
      } else {
        setFormattedNumber("invalid");
        return false;
      }
    }

    //phone can be US or IL
    let phoneNumber = parsePhoneNumberFromString(phone, "US");
    if (!phoneNumber || !phoneNumber.isValid()) {
      phoneNumber = parsePhoneNumberFromString(phone, "IL");
      if (!phoneNumber || !phoneNumber.isValid()) {
        setFormattedNumber("invalid");
        return false;
      }
    }

    setCheckingPhone(true);
    setFormattedNumber(phoneNumber);
    setUserPhone(phone);
  };

  const checkCode = (code) => {
    setCodeErrorMessage(false);
    
    dispatch(checkCodeLogin(formattedNumber.number, code, roomId))
      .then(() => {
        history.push("/telehealth/waitingRoom");
      })
      .catch(() => {
        setCodeErrorMessage(true);
      });
  };

  return (
    <>
      {isPhoneCorrect ? (
        <LoginCode
          displayErrorMessage={codeErrorMessage}
          userPhone={userPhone}
          backToPhone={() => {
            setIsPhoneCorrect(false);
            setCodeErrorMessage(false);
          }}
          sendCodeBack={(type) => {
            setSendType(type);
            checkPhone();
          }}
          handleCode={checkCode}
        ></LoginCode>
      ) : (
        <LoginPhone
          userPhone={userPhone}
          displayErrorMessage={phoneErrorMessage}
          handlePhone={checkPhone}
          checkingPhone={checkingPhone}
        ></LoginPhone>
      )}
    </>
  );
};

export default LoginPage;
