const starSticker = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="stamp-icon">
<g filter="url(#filter0_d_563_1691)">
<path d="M27.167 20.3804L20.1722 9.14117L19.7342 22.372L8.15673 25.4742L19.4155 29.6862L18.9318 42.2573L26.4462 32.556L37.8197 37.1963L31.1153 26.5513L38.7444 17.2783L27.167 20.3804Z" fill="#FFB200"/>
<path d="M27.167 20.3804L20.1722 9.14117L19.7342 22.372L8.15673 25.4742L19.4155 29.6862L18.9318 42.2573L26.4462 32.556L37.8197 37.1963L31.1153 26.5513L38.7444 17.2783L27.167 20.3804Z" stroke="white"/>
</g>
<defs>
<filter id="filter0_d_563_1691" x="4.51904" y="7.47949" width="35.6022" height="38.3168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-1" dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_563_1691"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_563_1691" result="shape"/>
</filter>
</defs>
</svg>
`;

export default starSticker;