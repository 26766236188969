import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import OptionIcon from "../../../images/icn_add.svg";
import SelectedOptionIcon from "../../../images/icn_chat.svg";
import "./style.css";
import OptionsMenuList from "./OptionsMenuList";

/* example
{
      title: "Edit Plan Details",
      icon: <Trash />
      onclick: () => {
        editPlan(planData.id);
      }
    },
    {
      title: "Delete Plan",
      onclick: () => {
        deleteTracks();
      },
      isRed: true,
      border: true//display border on bottom
    }
 */

const OptionsMenu = ({
  items,
  Icon,
  SelectedIcon,
  positionRelativeTo,
  handleOpenClose,
  openOn = "right"
}) => {
  const toggleOpen = (e) => {
    e.preventDefault(); //avoid to continue to NavLink
    setIsOpen(!isOpen);
  };

  const getMenuIcon = () => {
    if (!Icon) return <OptionIcon onClick={toggleOpen} />;
    if (!React.isValidElement(Icon)) return <Icon onClick={toggleOpen} />;
    return React.cloneElement(Icon, {
      onClick: toggleOpen
    });
  };

  const getSelectedMenuIcon = () => {
    if (SelectedIcon) {
      if (!React.isValidElement(SelectedIcon))
        return <SelectedIcon onClick={toggleOpen} />;
      return React.cloneElement(SelectedIcon, {
        onClick: toggleOpen
      });
    }

    if (Icon) return getMenuIcon();
    return <SelectedOptionIcon onClick={toggleOpen} />;
  };

  const [isOpen, setIsOpen] = useState(false);
  const [mouseInDiv, setMouseInDiv] = useState(false);

  useEffect(() => {
    if (positionRelativeTo) {
      document.getElementById(positionRelativeTo).style.position = "relative";
    }
  }, []);

  const getDisplayedOptionIcon = () => {
    if (!isOpen) {
      return getMenuIcon();
    } else {
      return getSelectedMenuIcon();
    }
  };

  const checkClickForClose = () => {
    if (!mouseInDiv) setIsOpen(false);
  };

  useEffect(() => {
    document.onclick = isOpen ? checkClickForClose : undefined;
  }, [isOpen, mouseInDiv]);

  useEffect(() => {
    handleOpenClose && handleOpenClose(isOpen);
  }, [isOpen]);

  return (
    <div
      className={`options-menu-container ${isOpen ? "opened" : ""}`}
      style={{ position: !positionRelativeTo ? "relative" : "" }}
      onMouseEnter={() => {
        setMouseInDiv(true);
      }}
      onMouseLeave={() => {
        setMouseInDiv(false);
      }}
    >
      {getDisplayedOptionIcon()}
      {isOpen && (
        <OptionsMenuList
          items={items}
          openOn={openOn}
          handleClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
};

OptionsMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      onclick: PropTypes.func.isRequired,
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      border: PropTypes.bool,
      isRed: PropTypes.bool
    })
  ).isRequired,
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  SelectedIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleOpenClose: PropTypes.func,
  positionRelativeTo: PropTypes.string,
  openOn: PropTypes.string
};

export default OptionsMenu;
