import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import VideoButton from "../common/VideoButton";
import {
  toogleAudio,
  toogleChat,
  toggleSettings,
  toggleVideo,
  toogleParticipantPopup,
} from "../../redux/slices/videoSlice";
import { useOutsideAlerter } from "../utilities/useOutsideAlerter";

const ButtonsBar = ({
  handleEndSession,
  handleSelectPresent,
  handleStopShareScreen,
  enableScreenShare,
  screenShared,
}) => {
  const { unreadMessage } = useSelector((state) => state.chat);
  const { participant, publishAudio, publishVideo } = useSelector(
    (state) => state.video
  );
  const dispatch = useDispatch();
  const [displayPresentOptions, setDisplayPresentOptions] = useState(false);
  const [hideBar, setHideBar] = useState(false);
  const [hideTimeout, setHideTimeout] = useState();

  const presentOptions = useRef(null);

  useOutsideAlerter(presentOptions, () => {
    setDisplayPresentOptions(false);
  });

  useEffect(() => {
    setHideTimeout(setTimeout(() => setHideBar(true), 3000));

      return () => {
        clearTimeout(hideTimeout);
        setHideTimeout(null);
      };
  }, [])
  

  return (
    <div
      className={"publisher-buttons " + (hideBar ? "hide" : "")}
      onMouseLeave={() => {
        setHideTimeout(setTimeout(() => {
          setHideBar(true);
        }, 3000))
      }}
      onMouseEnter={() => {
        clearTimeout(hideTimeout);
        setHideTimeout(null);
        setHideBar(false);
      }}
    >
      <div className="publisher-buttons-left">
        <VideoButton
          type="settings"
          text="Settings"
          handleClick={() => {
            dispatch(toggleSettings());
          }}
        ></VideoButton>
        <VideoButton
          type={"camera" + (publishVideo ? "" : "-off")}
          text="Video"
          handleClick={() => {
            dispatch(toggleVideo());
          }}
        ></VideoButton>
        <VideoButton
          type={"microphone" + (publishAudio ? "" : "-off")}
          text="Mic"
          handleClick={() => {
            dispatch(toogleAudio());
          }}
        ></VideoButton>
      </div>
      <div className="end-session-btn" onClick={handleEndSession}>
        {participant && participant.role === "owner" ? "Leave Session" : "Hang Up"}
      </div>
      <div className="publisher-buttons-right">
        {displayPresentOptions && (
          <div ref={presentOptions} className="present-options">
            <div
              className="present-option"
              onClick={() => {
                setDisplayPresentOptions(false);
                handleSelectPresent("share_screen");
              }}
            >
              Share screen
            </div>
            <div
              className="present-option"
              onClick={() => {
                setDisplayPresentOptions(false);
                handleSelectPresent("white_board");
              }}
            >
              Whiteboard
            </div>
          </div>
        )}
        {enableScreenShare &&
          (!screenShared ? (
            <VideoButton
              type="share-screen"
              text="Present"
              handleClick={() => {
                setDisplayPresentOptions(!displayPresentOptions);
              }}
            ></VideoButton>
          ) : (
            <VideoButton
              type="stop-share-screen"
              text="Stop share"
              handleClick={() => {
                handleStopShareScreen();
              }}
            ></VideoButton>
          ))}
        <VideoButton
          type="add-participant"
          text="Participants"
          handleClick={() => {
            dispatch(toogleParticipantPopup());
          }}
        ></VideoButton>
        <VideoButton
          type={"chat" + (unreadMessage ? "-new" : "")}
          text="Chat"
          handleClick={() => {
            dispatch(toogleChat());
          }}
        ></VideoButton>
      </div>
    </div>
  );
};

ButtonsBar.propTypes = {
  handleEndSession: PropTypes.func,
  handleSelectPresent: PropTypes.func,
  handleStopShareScreen: PropTypes.func,
  enableScreenShare: PropTypes.bool,
  screenShared: PropTypes.bool,
};

export default ButtonsBar;
