import React from "react";
import "./css/videoButton.css";
import CameraIcon from "./../../images/cam_icon.svg";
import CameraIconOff from "./../../images/icn_camera_off.svg";
import MicrophoneIcon from "./../../images/mic_icon.svg";
import MicrophoneIconOff from "./../../images/icn_mic_off.svg";
import MiniCameraIcon from "./../../images/video-small-black.svg";
import MiniCameraIconOff from "./../../images/video-small-black-off.svg";
import MiniMicrophoneIcon from "./../../images/micro-small-black.svg";
import MiniMicrophoneIconOff from "./../../images/micro-small-black-off.svg";
import CameraIconGray from "./../../images/cam_icon_gray.svg";
import CameraIconOffGray from "./../../images/cam_icon_gray_off.svg";
import MicrophoneIconGray from "./../../images/mic_icon_gray.svg";
import MicrophoneIconOffGray from "./../../images/icn_mic_off_gray.svg";
// import ChatIcon from "./../../images/icn_chat.svg";
import ChatIconOn from "./../../images/icn_chat_white.svg";
import ChatIconNew from "./../../images/icn_chat_badge.svg";
import AddParticipIcon from "./../../images/icn_add.svg";
import SettingsIcon from "./../../images/icn_settings.svg";
import ScreenShareIcon from "./../../images/screen_share_black.svg";
import CancelScreenShareIcon from "./../../images/cancel_share_screen.svg";
import PropTypes from "prop-types";

const VideoButton = ({ type, handleClick, text, style }) => {
  const getBtn = () => {
    switch (type) {
      case "camera-gray":
        return <CameraIconGray />;
      case "camera-gray-off":
        return <CameraIconOffGray />;
      case "microphone-gray":
        return <MicrophoneIconGray />;
      case "microphone-gray-off":
        return <MicrophoneIconOffGray />;
      case "camera":
        return <CameraIcon />;
      case "camera-off":
        return <CameraIconOff />;
      case "mini-camera":
        return <MiniCameraIcon />;
        case "mini-camera-off":
        return <MiniCameraIconOff />;
        case "mini-microphone":
        return <MiniMicrophoneIcon />;
      case "mini-microphone-off":
        return <MiniMicrophoneIconOff />;
      case "microphone":
        return <MicrophoneIcon />;
      case "microphone-off":
        return <MicrophoneIconOff />;
      case "chat":
        return <ChatIconOn />;
        // case "chat-on":
        // return <ChatIcon />;
      case "chat-new":
        return <ChatIconNew />;
      case "add-participant":
        return <AddParticipIcon />;
      case "settings":
        return <SettingsIcon />;
      case "share-screen":
        return <ScreenShareIcon />;
      case "stop-share-screen":
        return <CancelScreenShareIcon />;
    }
  };

  return (
    <div className="video-button-container" onClick={handleClick} style={style}>
      <div className="video-button">
        {getBtn()}
      </div>
      {text && <div className="video-button-text">{text}</div>}
    </div>
  );
};

VideoButton.propTypes = {
  type: PropTypes.string,
  handleClick: PropTypes.func,
  text: PropTypes.string,
  style: PropTypes.object
};

export default VideoButton;
