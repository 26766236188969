/*eslint no-unused-vars: "off"*/
import React, { useState, useEffect } from "react";
import IconButton from "../common/IconButton";
import "./chatStyle.css";
import SendBtn from "../../images/btn_send.svg";
import ChatMessage from "./ChatMessage";
import { getToken } from "../../api/storageConfig";
import pipe from "../../api/chat";
import {
  setAllMessagesAsRead,
  addChatMessage,
  getChatMessages,
  newUnreadMessage
} from "../../redux/slices/chatSlice";
import { displayErrorPage } from "../../redux/slices/globalSlice";
import { sendChatMessage, setAsMessageRead } from "../../api/chatAPI";
import PropTypes from "prop-types";
import { toogleChat } from "../../redux/slices/videoSlice";
import { useDispatch, useSelector } from "react-redux";

const VideoChat = ({
  patientId,
  idsToName
}) => {
  const dispatch = useDispatch();
  const {participantId, sessionId, showChat, roomId} = useSelector(state => state.video);
  const {chatMessages} = useSelector(state => state.chat);

  const chatUrl = process.env.BASE_API_URL;
  const [msgText, setMsgText] = useState("");

  const messageReceived = function (data) {
    const event = data[0].event;

    if (event === "communication_item_created") {
      const d = data[0].data.channelItem;
      if (d.domainEvent === "chat_message_sent") {
        dispatch(addChatMessage({
          icon: d.sender.picture && encodeURI(d.sender.picture),
          fromId: d.sender.id,
          from: idsToName[d.sender.id],
          date: new Date(d.ts),
          text: d.message,
          read: showChat
        }));

        if (showChat && d.sender.id !== participantId) {
          dispatch(setAsMessageRead(sessionId, roomId));
        }

        if (!showChat) {
          dispatch(newUnreadMessage());
        }
      }
    }
  };

  const sendNewMessage = function (e) {
    if (e.keyCode && e.keyCode !== 13) return;

    if (msgText.length) {
      sendChatMessage(sessionId, roomId, msgText);
    }

    setMsgText("");
  };

  useEffect(() => {
    //on chat, scroll should be on bottom
    var element = document.getElementById("chat-body");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [chatMessages, showChat]);

  useEffect(() => {
    if (!showChat && pipe.messageReceived) {
      dispatch(setAllMessagesAsRead());
    }

    pipe.messageReceived = messageReceived;
  }, [showChat]);

  useEffect(() => {
    dispatch(getChatMessages(sessionId, roomId)).catch(() => {
      console.log("cannot load chat messages");
    });

    try {
      const token = getToken().substr(7); //remove "Bearer "

      pipe.connect(token, chatUrl, roomId);

      return () => {
        try {
          pipe.socket.disconnect();
        } catch (error) {
          console.log("Cannot disconnect WebSocket")
        }
      };
    } catch (error) {
      dispatch(displayErrorPage({
        title: "Unauthorized",
        message: "You cannot access this page"
      }));
    }
  }, []);

  return (
    <>
      {showChat && (
        <div id="video-chat">
          <div id="chat-header">
            <IconButton type="close" onClick={() => {
              dispatch(toogleChat());
              }}></IconButton>
            <div className="chat-title">Chat</div>
          </div>
          <div id="chat-body">
            {chatMessages.map((message, index, array) => {
              return (
                <ChatMessage
                  key={index}
                  ownMessage={message.fromId === participantId}
                  patientMessage={message.fromId === patientId}
                  message={message}
                  index={index}
                  messagesArr={array}
                ></ChatMessage>
              );
            })}
          </div>
          <div id="chat-footer">
            <div id="footer-container">
              {/* {otherParticipants.length > 1 && (
            <div id="message-dest">
              Sending message to:{" "}
              <select defaultValue="All">
                <option>All</option>
                {participantsName.map((p, index) => {
                  return <option key={index}>{p}</option>;
                })}
              </select>{" "}
            </div>
          )} */}
              <div id="send-message">
                <input
                  type="text"
                  autoFocus
                  value={msgText}
                  onKeyUp={sendNewMessage}
                  onChange={(event) => {
                    setMsgText(event.target.value);
                  }}
                />
                <div className="send-button" onClick={sendNewMessage}>
                  <SendBtn />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

VideoChat.propTypes = {
  patientId: PropTypes.string,
  idsToName: PropTypes.object
};

export default VideoChat;
