import { createSlice } from "@reduxjs/toolkit";
import * as storageConfig from "../../api/storageConfig";
import * as videoAPI from "../../api/videoAPI";
import defaultAvatar from "./../../images/user_male.jpeg";
import { getErrorDetails } from "../../components/utilities/index";
import { displayErrorPage, displayEndPage } from "../slices/globalSlice";

const videoSlice = createSlice({
  name: "video",
  initialState: {
    roomId: storageConfig.getRoomId(),
    sessionId: storageConfig.getSessionId(),
    sessionLink: storageConfig.getSessionLink() || "",

    publishVideo: storageConfig.getPublishVideo(),
    publishAudio: storageConfig.getPublishAudio(),
    audioSource: storageConfig.getAudioSource(),
    videoSource: storageConfig.getVideoSource(),

    participants: storageConfig.getParticipantList() || [],
    participantId: storageConfig.getParticipantId(),
    participant: storageConfig.getParticipant(),
    patient: storageConfig.getPatient(),
    programId: storageConfig.getProgramId(),
    isWellness: storageConfig.getIsWellness(),

    fromMeetingPage: false,

    userAvoidAccessToCamera: false,
    userAvoidAccessToMicrophone: false,
    sessionTerminated: true,
    ringingPatient: false,

    //show meeting room popups
    showChat: false,
    showSettings: false,
    feedback: undefined
  },
  reducers: {
    loadVideoParams(state, action) {
      const waitingRoomSettings = action.payload;

      state.sessionId = waitingRoomSettings.sessionId;
      state.roomId = waitingRoomSettings.roomId;
      state.participantId = waitingRoomSettings.participantId;
    },
    
    loadFeedbackConfigSuccess(state, action) {
      state.feedback = action.payload;
    },
    loadWaitingRoomSuccess(state, action) {
      const waitingRoomSettings = action.payload;

      const participantId =
        state.participantId || storageConfig.getParticipantId();
      if (waitingRoomSettings.participants) {
        state.participants = waitingRoomSettings.participants || [];

        state.participant = waitingRoomSettings.participants.find(
          (p) => p.id === participantId
        );
        state.patient = waitingRoomSettings.participants.find(
          (p) => p.role === "owner"
        );
      }

      if (waitingRoomSettings.link) {
        state.sessionLink = waitingRoomSettings.link;
      }

      state.sessionId = waitingRoomSettings.sessionId;
      state.roomId = waitingRoomSettings.roomId;
      state.isWellness = waitingRoomSettings.isWellness;
      state.programId = waitingRoomSettings.programId;
    },
    leaveMeetingPage(state) {
      state.fromMeetingPage = true;
    },
    userAvoidAccessToMicrophone(state) {
      state.userAvoidAccessToMicrophone = true;
    },
    userAvoidAccessToCamera(state) {
      state.userAvoidAccessToCamera = true;
    },
    sessionCreated(state) {
      state.sessionTerminated = false;
    },
    callingPatient(state) {
      state.isCallingPatient = true;
    },
    callingPatientEnded(state) {
      state.isCallingPatient = false;
    },
    setRoomId(state, action) {
      storageConfig.setRoomId(action.payload);
      state.roomId = action.payload;
    },
    changeVideoSource(state, action) {
      storageConfig.setVideoSource(action.payload);
    },
    changeAudioSource(state, action) {
      storageConfig.setAudioSource(action.payload);
    },
    updateSessionId(state, action) {
      storageConfig.setSessionId(action.payload);
      state.sessionId = action.payload;
    },
    terminateSession(state) {
      state.sessionTerminated = true;
    },
    toogleAudio(state) {
      storageConfig.togglePublishAudio();
      state.publishAudio = !state.publishAudio;
    },
    toggleVideo(state) {
      storageConfig.togglePublishVideo();
      state.publishVideo = !state.publishVideo;
    },
    toogleChat(state) {
      state.showChat = !state.showChat;
    },
    toggleSettings(state) {
      state.showSettings = !state.showSettings;
    },
    toogleParticipantPopup(state) {
      state.showParicipantsPopup = !state.showParicipantsPopup;
    },
    toggleDisplayFeedback(state) {
      state.displayFeedback = !state.displayFeedback;
    }
  }
});

export default videoSlice.reducer;
export const {
  loadVideoParams,
  loadWaitingRoomSuccess,
  leaveMeetingPage,
  userAvoidAccessToMicrophone,
  userAvoidAccessToCamera,
  sessionCreated,
  callingPatient,
  callingPatientEnded,
  setRoomId,
  changeAudioSource,
  updateSessionId,
  terminateSession,
  toogleAudio,
  toggleVideo,
  toogleChat,
  toggleSettings,
  toogleParticipantPopup,
  toggleDisplayFeedback,
  changeVideoSource,
  loadFeedbackConfigSuccess
} = videoSlice.actions;

export const checkCodeLogin = (phone, code, roomId) => async (dispatch) => {
  // dispatch(beginApiCall());
  return videoAPI
    .checkCodeLogin(phone, code, roomId)
    .then((waitingRoomSettings) => {
      storageConfig.setToken(waitingRoomSettings.accessToken);
      storageConfig.setRoomId(waitingRoomSettings.roomId);
      storageConfig.setParticipantId(waitingRoomSettings.participantId);
      storageConfig.setSessionId(waitingRoomSettings.sessionId);
      dispatch(loadWaitingRoomSuccess(waitingRoomSettings));
      //   dispatch(loadVideoParams(waitingRoomSettings));
    })
    .catch((error) => {
      // dispatch(apiCallError(error));
      throw error;
    });
};

export const loadWaitingRoom = (sessionId, roomId) => async (dispatch) => {
  //   dispatch(beginApiCall());
  const sId = sessionId || storageConfig.getSessionId();
  const rId = roomId || storageConfig.getRoomId();

  return videoAPI
    .getWaitingRoom(sId, rId)
    .then((response) => {
      if (response.status === 200) {
        return response.json().then((waitingRoomSettings) => {
          waitingRoomSettings.participants =
            waitingRoomSettings.participants.map((p) => {
              return { ...p, picture: encodeURI(p.picture || defaultAvatar) };
            });

          storageConfig.setRoomId(waitingRoomSettings.roomId);
          storageConfig.setSessionLink(waitingRoomSettings.link);
          storageConfig.setSessionId(waitingRoomSettings.sessionId);
          storageConfig.setParticipantList(waitingRoomSettings.participants);
          storageConfig.setIsWellness(waitingRoomSettings.isWellness);
          storageConfig.setProgramId(waitingRoomSettings.programId);
          //   dispatch(loadVideoParams(waitingRoomSettings));
          dispatch(loadWaitingRoomSuccess(waitingRoomSettings));
        });
      } else {
        return response.json().then((r) => {
          const errorDetails = getErrorDetails(r.message);
          if (errorDetails.type === "error") {
            dispatch(
              displayErrorPage({
                title: errorDetails.title,
                message: errorDetails.message
              })
            );
          } else {
            dispatch(
              displayEndPage({
                title: errorDetails.title,
                message: errorDetails.message
              })
            );
          }
        });
      }
    })
    .catch((error) => {
      //   dispatch(apiCallError(error));
      throw error;
    });
};

export const loadFeedbackConfig = () => async (dispatch) => {
  return videoAPI.getFeedbackConfiguration().then((response) => {
    if (response.status === 200) {
      return response.json().then((config) => {
        dispatch(loadFeedbackConfigSuccess(config));
      });
    } else {
      dispatch(loadFeedbackConfigSuccess({payload: {
        videoSessionTimeSec: 300,
        tags: [
          {
            name: "Audio"
          },
          {
            name: "Video"
          },
        ]
      }}));
    }
  })
};

export const callPatient =
  (roomId, sessionId, patientId) => async (dispatch) => {
    //   dispatch(callingPatient());
    return videoAPI
      .callParticipant(sessionId, roomId, patientId)
      .then((res) => {})
      .catch(() => {
        dispatch(displayErrorPage());
      });
  };

export const callMissed = () => async (dispatch, getState) => {
  const roomId = getState().video.roomId;
  const sessionId = getState().video.sessionId;
  const patient = storageConfig.getPatient();

  return videoAPI
    .callMissed(sessionId, roomId, patient.id)
    .then((res) => {})
    .catch(() => {
      dispatch(displayErrorPage());
    });
};

export const sendEmailVideoRequest = (email) => async (dispatch, getState) => {
  return videoAPI
    .sendEmailVideoRequest(getState().video.roomId, email)
    .then((res) => {})
    .catch(() => {
      dispatch(displayErrorPage());
    });
};
