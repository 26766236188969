import io from "socket.io-client";

class Pipe {
  connect(token, url, roomId) {
    if (this.socket) return;

    this.socket = io(url, {
      transports: ["websocket", "polling"],
      auth: {
        token
      },
      query: {
        roomId,
        type: "telehealth"
      }
    });

    const socket = this.socket;

    socket.on("connect_error", (err) => {
      console.log(socket);
      console.log(err.message);
    });

    socket.on("connect", () => {
      this.isConnected = true;
    });

    socket.on("event", (data) => {
      this.messageReceived(data);
    });

    socket.on("disconnect", (response, err) => {
      this.isConnected = false;
    });
  }

  disconnect() {
    if (this.socket) {
      this.wantToDisconnect = true;
      this.socket.disconnect();
    }
  }
  
}

export default new Pipe();