/*eslint no-unused-vars: "off"*/
import React, { useEffect, useState } from "react";
import InputSourcesList from "./../InputSourcesList";
import VideoButton from "./../common/VideoButton";
import Publisher from "../utilities/Publisher";
import PropTypes from "prop-types";
import {
  toogleAudio,
  toggleVideo,
  changeAudioSource,
  changeVideoSource,
} from "../../redux/slices/videoSlice";
import SmallCameraIcon from "./../../images/small_video.svg";
import SmallMicrophoneIcon from "./../../images/small_audio.svg";
import { addUserMessage } from "../../redux/slices/globalSlice";
import { useSelector, useDispatch } from "react-redux";

function WaitingRoomSettings({ handleDisabledBtn }) {
  const { participant, publishAudio, publishVideo, audioSource, videoSource } =
    useSelector((state) => state.video);
  const dispatch = useDispatch();

  const [publisher, setPublisher] = useState({});

  const getPublisherSettings = () => {
    return {
      audioSource,
      videoSource,
      publishAudio,
      publishVideo,
      picture: participant && participant.picture,
    };
  };

  useEffect(() => {
    if (!participant) return;

    const pbSettings = getPublisherSettings();

    window.myPublisher = new Publisher({
      element: document.getElementById("wrs-publisher"),
      settings: pbSettings,
      onAfterInit: () => {
        handleDisabledBtn(false);
      },
      onError: (code) => {
        if (code === 1500) {
          dispatch(
            addUserMessage(
              "warning",
              "The selected voice or video devices are unavailable. Verify that the chosen devices are not in use by another application.",
              null,
              true
            )
          );
          handleDisabledBtn(true);
        }
      },
    });

    setPublisher(window.myPublisher);
  }, [participant]);

  useEffect(() => {
    if (!videoSource) return;

    publisher instanceof Publisher && publisher.changeVideoSource(videoSource);
  }, [videoSource]);

  useEffect(() => {
    publisher instanceof Publisher && publisher.changeAudioSource(audioSource);
  }, [audioSource]);

  useEffect(() => {
    publisher instanceof Publisher && publisher.publishAudio(publishAudio);
  }, [publishAudio]);

  useEffect(() => {
    publisher instanceof Publisher && publisher.publishVideo(publishVideo);
  }, [publishVideo]);

  return (
    <div className="wrs-container">
      <div className="wrs-publisher-container">
        <div id="wrs-publisher"></div>
        <div id="wrs-publisher-buttons">
          <VideoButton
            type={"mini-camera" + (publishVideo ? "" : "-off")}
            handleClick={() => {
              dispatch(toggleVideo());
            }}
            style={{ justifySelf: "right" }}
          ></VideoButton>
          <VideoButton
            type={"mini-microphone" + (publishAudio ? "" : "-off")}
            handleClick={() => {
              dispatch(toogleAudio());
            }}
            style={{ justifySelf: "left" }}
          ></VideoButton>
        </div>
      </div>
      <div className="sources">
        <div className="title">Sources</div>
        <div className="source-element" style={{ marginBottom: "16px" }}>
          <SmallCameraIcon />
          <InputSourcesList
            input="videoInput"
            selectedSource={audioSource}
            handleChange={(videoInput) => {
              dispatch(changeVideoSource(videoInput));
            }}
          ></InputSourcesList>
        </div>
        <div className="source-element">
          <SmallMicrophoneIcon />
          <InputSourcesList
            input="audioInput"
            selectedSource={audioSource}
            handleChange={(audioInput) => {
              dispatch(changeAudioSource(audioInput));
            }}
          ></InputSourcesList>
        </div>
      </div>
    </div>
  );
}

WaitingRoomSettings.propTypes = {
  handleDisabledBtn: PropTypes.func,
};

export default WaitingRoomSettings;
