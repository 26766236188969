import React, { useState } from "react";
import live from "./../../images/img_live.png";
import defaultAvatar from "./../../images/user_male.jpeg";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import "./participantsList.css";
import EnvelopeIcon from "../../images/envelope.svg";
import EnvelopeGrayIcon from "../../images/envelope_gray.svg";
import EnvelopeSendIcon from "../../images/envelop_after.svg";
import PhoneIcon from "../../images/phone.svg";
import PhoneGrayIcon from "../../images/phone_gray.svg";
import {
  callPatient,
  sendEmailVideoRequest,
} from "../../redux/slices/videoSlice";
import { getPatient } from "../../api/storageConfig";

const ParticipantElement = ({
  participant,
  id,
  inMeetingRoom,
  handleCall,
  handleSendEmail,
}) => {
  const [isHoverMail, setIsHoverMail] = useState(false);
  const [isHoverPhone, setIsHoverPhone] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [isMailing, setIsMailing] = useState(false);
  const [displayRingingText, setDisplayRingingText] = useState(false);
  const [displayMailingText, setDisplayMailingText] = useState(false);

  return (
    <div className="participant-element">
      <div className="participant-icon">
        <img
          src={participant.picture || defaultAvatar}
          height="40px"
          width="40px"
          alt="avatar"
        />
      </div>
      <div className="participant-name-and-email">
        <div className="participant-name">
          {participant.firstName +
            " " +
            participant.lastName +
            (participant.id === id ? " (You)" : "")}
          {participant.id !== id && displayRingingText && (
            <span className="message">Ringing...</span>
          )}
          {participant.id !== id && displayMailingText && (
            <span className="message">Invite sent!</span>
          )}
        </div>
        {!inMeetingRoom && (
          <div className="participant-email">{participant.email}</div>
        )}
      </div>
      {inMeetingRoom && participant.role === "owner" && participant.id !== id && (
        <div className="participant-call-icons">
          <div
            className="participant-call-icon mail-icon"
            onClick={() => {
              handleSendEmail(participant.email);
              setIsMailing(true);
              setDisplayMailingText(true);
            }}
            onMouseEnter={() => {
              if (participant.id === id) return;
              setIsHoverMail(true);
            }}
            onMouseLeave={() => {
              setIsHoverMail(false);
            }}
          >
            {participant.email && !isRinging && !isHoverPhone && (
              <>
                {isMailing ? (
                  <EnvelopeSendIcon />
                ) : isHoverMail ? (
                  <EnvelopeIcon />
                ) : (
                  <EnvelopeGrayIcon />
                )}
                {isHoverMail && !isMailing && (
                  <span className="title">Invite</span>
                )}
              </>
            )}
          </div>
          <div
            className="participant-call-icon phone-icon"
            onClick={() => {
              if (isRinging) return;

              handleCall();
              setIsRinging(true);
              setDisplayRingingText(true);
            }}
            onMouseEnter={() => {
              if (participant.id === id) return;
              setIsHoverPhone(true);
            }}
            onMouseLeave={() => {
              setIsHoverPhone(false);
            }}
          >
            {!isMailing && !isHoverMail && (
              <>
                {isHoverPhone || isRinging ? <PhoneIcon /> : <PhoneGrayIcon />}
                {isHoverPhone && !isRinging && (
                  <span className="title">Ring</span>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ParticipantElement.propTypes = {
  participant: PropTypes.object,
  id: PropTypes.string,
  inMeetingRoom: PropTypes.bool,
  isCalling: PropTypes.bool,
  isSendingEmail: PropTypes.bool,
  handleCall: PropTypes.func,
  handleSendEmail: PropTypes.func,
};

function ParticipantsList({ inMeetingRoom }) {
  const dispatch = useDispatch();
  const {
    participants,
    participantId,
    roomId,
    sessionId,
    isCallingPatient,
    isSendingEmail,
  } = useSelector((state) => state.video);

  return (
    <div className="participants-list-area">
      <div className="participant-title">Participants:</div>
      <div className="participants-list-container">
        <div className="participants-list">
          {participants.map((p, index) => {
            return (
              <ParticipantElement
                key={index}
                id={participantId}
                participant={p}
                inMeetingRoom={inMeetingRoom}
                handleCall={() => {
                  const patient = getPatient();
                  dispatch(
                    callPatient(roomId, sessionId, patient && patient.id)
                  );
                }}
                handleSendEmail={(email) => {
                  dispatch(sendEmailVideoRequest(email));
                }}
                isCalling={isCallingPatient}
                isSendingEmail={isSendingEmail}
              ></ParticipantElement>
            );
          })}
        </div>
      </div>
    </div>
  );
}

ParticipantsList.propTypes = {
  inMeetingRoom: PropTypes.bool,
};

export default ParticipantsList;
