/*eslint no-unused-vars: "off"*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CopyIcon from "../images/copy_icon.svg"

const SessionLink = () => {
  const link = useSelector(state => state.video && state.video.sessionLink);

  const copyLink = () => {
    const l = document.getElementById("copy-link-input");
    l.select();
    l.setSelectionRange(0, 99999); /*For mobile devices*/
    const n = document.createElement("textarea");
    n.style.position = "fixed";
    n.style.top = 0;
    n.style.left = 0;
    n.style.width = "2em";
    n.style.height = "2em";
    n.style.padding = 0;
    n.style.border = "none";
    n.style.outline = "none";
    n.style.boxShadow = "none";
    n.style.background = "transparent";
    n.value = l.value;
    document.body.appendChild(n);
    n.select();
    try {
      var a = document.execCommand("copy")
        ? "successful"
        : "unsuccessful";
      "successful" === a
        ? alert("Link copying was " + a)
        : prompt(
            "Copy is not supported on Safari. Please press ⌘+C or Ctrl+C to copy manually."
          );
    } catch (e) {
      alert("Oops, unable to copy");
    }
    document.body.removeChild(n);
  }

  return (
    <div
      className="session-link-container"
      style={{
        paddingBottom: "23px"
      }}
    >
      <div
        className="session-link-content"
      >
        Copy Session Invite
      </div>
      <div className="link-and-input" >
        <CopyIcon onClick={copyLink} />
        <input id="copy-link-input" disabled type="text" defaultValue={link} />
      </div>
    </div>
  );
};

export default SessionLink;
