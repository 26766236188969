import { createSlice } from "@reduxjs/toolkit";
import { alertMessages as messages } from "../../components/utilities/index";

const initialState = {
  userMessageArray: [],
  invalidPage: false,
  errorPage: false,
  endPage: false,
  message: null,
  title: null,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    addUserMessagePopup(state, action) {
      const messageItem = action.payload;
      /**
       * If not valid message not insert it to the message array
       * */
      if (!messageItem.text || !messageItem.type || !messageItem.id)
        return state;

      let arr = [],
        flag = true;
      for (let index = 0; index < state.userMessageArray.length; index++) {
        const element = state.userMessageArray[index];
        if (element.id !== messageItem.id) {
          arr.push({ ...element });
        } else {
          //if I want to add text in existing message
          arr.push(messageItem);
          flag = false;
        }
      }

      if (flag) {
        arr.push(messageItem);
      }

      state.userMessageArray = arr;
    },
    displayErrorPage(state, action) {
      state.errorPage = true;
      state.message =
        (action.payload && action.payload.message) ||
        messages.error.message;
      state.title =
        (action.payload && action.payload.title) ||
        messages.error.title;
    },
    deleteUserMessage(state, action) {
      const messageId = action.payload && action.payload.id;
      state.userMessageArray = state.userMessageArray.filter(
        (item) => item.id !== messageId
      );
    },
    displayInvalidPage(state, action) {
      state.invalidPage = true;
      state.message =
        (action.payload &&
          action.payload &&
          action.payload.message) ||
        messages.invalid.message;
      state.title =
        (action.payload &&
          action.payload &&
          action.payload.title) ||
        messages.invalid.title;
    },
    displayEndPage(state, action) {
      if (state.errorPage) return state;

      state.endPage = true;
      state.message =
        (action.payload && action.payload.message) || messages.end.message;
      state.title =
        (action.payload && action.payload.title) || messages.end.title;
    },
    leaveEndPage(state, action) {
      state.endPage = false;
    },
    resetState(state, action) {
      return initialState;
    },
  },
});

export default globalSlice.reducer;

export const {
  addUserMessagePopup,
  displayErrorPage,
  deleteUserMessage,
  displayInvalidPage,
  leaveEndPage,
  displayEndPage,
  resetState,
} = globalSlice.actions;

export const addUserMessage = (type, text, id, pin, onClosed) => (dispatch) => {
  const messageItem = {
    type,
    text,
    pin,
    id: id || "user_message_id" + new Date().getTime(),
    onClosed,
  };

  dispatch(addUserMessagePopup(messageItem));
  if (!pin) {
    setTimeout(() => {
      dispatch(deleteUserMessage(messageItem.id));
    }, 5000);
  }
};
