import React from "react";
import { render } from "react-dom";
import VideoAdaptor from "./VideoAdaptor";

const a = render(<VideoAdaptor />, document.getElementById("app"));

// export default a;
// const GateTest = () => {
//   return (
//     <div>
//       <VideoAdaptor />
//     </div>
//   );
// };

// export default GateTest;

// import App from "./components/App";
// export default App;
