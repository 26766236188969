/*eslint no-unused-vars: "off"*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../common/Popup";
import ParticipantsList from "./ParticipantsList";
import WaitingRoomSettings from "./WaitingRoomSettings";
import { getToken } from "../../api/storageConfig";
import { useHistory, Redirect } from "react-router-dom";
import "./style.css";
import {
  userAvoidAccessToCamera,
  userAvoidAccessToMicrophone,
  loadWaitingRoom
} from "../../redux/slices/videoSlice";
import {
  addUserMessage,
  deleteUserMessage,
  displayErrorPage
} from "../../redux/slices/globalSlice";
import Button from "../common/Button";
import SessionLink from "../SessionLink";

const WaitingRoom = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { roomId, sessionId, participants, participant, fromMeetingPage, isWellness } =
    useSelector((state) => state.video);

  const checkToken = () => {
    if (!getToken()) {
      dispatch(
        displayErrorPage({
          title: "Unauthorized",
          message: "You cannot access this page"
        })
      );

      return <div></div>;
    }
  };

  const checkCameraAuthorization = () => {
    navigator.permissions.query({ name: "camera" }).then(function (result) {
      setCameraAccess(result.state === "granted");
      setCameraAsked(true);
    });

    navigator.permissions.query({ name: "microphone" }).then(function (result) {
      setMicroAccess(result.state === "granted");
      setMicroAsked(true);
    });
  };

  const [microAccess, setMicroAccess] = useState(false);
  const [cameraAccess, setCameraAccess] = useState(false);
  const [microAsked, setMicroAsked] = useState(false);
  const [cameraAsked, setCameraAsked] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(!sessionId);
  const [isOrganizer, setIsOrganizer] = useState(false);

  useEffect(() => {
    if (!microAsked || !cameraAsked) return;

    let message = undefined;
    if (!cameraAccess && !microAccess) {
      message = "Please allow access to camera and microphone";
    } else if (!cameraAccess) {
      message = "Please allow access to camera";
    } else if (!microAccess) {
      message = "Please allow access to microphone";
    }

    if (message) {
      dispatch(
        addUserMessage("Warning", message, "accessCamera", true, () => {
          navigator.permissions
            .query({ name: "microphone" })
            .then(function (result) {
              if (result.state !== "granted") {
                dispatch(userAvoidAccessToMicrophone());
              }
            });

          navigator.permissions
            .query({ name: "camera" })
            .then(function (result) {
              if (result.state !== "granted") {
                dispatch(userAvoidAccessToCamera());
              }
            });
        })
      );
    } else {
      dispatch(deleteUserMessage("accessCamera"));
    }
  }, [microAsked, cameraAsked, microAccess, cameraAccess]);

  useEffect(() => {
    if (!participant) {
      return;
    }

    setIsOrganizer(participant.role === "organizer");
  }, [participant]);

  useEffect(() => {
    checkToken();
    checkCameraAuthorization();
  }, []);

  useEffect(() => {
    if (!roomId) return;
    if (participants.length === 0) {
      dispatch(loadWaitingRoom(sessionId, roomId)).catch((error) => {
        console.error("Error in waiting room loading", error);
      });
    }
  }, [participants, roomId]);

  useEffect(() => {
    if (sessionId) {
      setDisabledBtn(false);
    }
  }, [sessionId]);

  const goToMeetingRoom = () => {
    history.push("/telehealth/meetingRoom");
  };

  return (
    <div>
      {fromMeetingPage && <Redirect to="/telehealth/end" />}
      <Popup
        hideCloseBtn={true}
        title="New Session"
        type="medium"
        bodyClass="wrp-body"
        options={{ hideFooter: true }}
      >
        <div className="waiting-room-container">
          <div className="waiting-room-content">
            <div className="link-and-participants">
              {isOrganizer && <SessionLink></SessionLink>}
              <ParticipantsList />
            </div>
            <WaitingRoomSettings
              handleDisabledBtn={(disabled) => {
                setDisabledBtn(disabled);
              }}
            />
          </div>

          <div className="waiting-room-bottom">
            {isWellness && <div className="consent-verbiage">
              By joining the session, you are agreeing to the{" "}
              <a
                href="https://www.valerahealth.com/telehealth-informed-consent/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telehealth Informed Consent
              </a>{" "}
              and{" "}
              <a
                href="https://www.valerahealth.com/aob/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Assignment of Benefits
              </a>{" "}
              above.
            </div>}
            <div className="waiting-room-buttons">
              <Button
                type="link"
                text="Cancel"
                style={{ width: "135px", height: "32px" }}
                onClick={() => {}}
              />
              <Button
                type="primary"
                text={isOrganizer ? "Start Session" : "Join Session"}
                onClick={() => {
                  goToMeetingRoom();
                }}
                style={{ width: "135px", height: "32px" }}
                disable={disabledBtn}
              ></Button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default WaitingRoom;
