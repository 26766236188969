/*eslint no-unused-vars: "off"*/
import { calcDimensions } from ".";
import Publisher from "./Publisher";

const OT = window.OT;

export default class Session {
  constructor(settings) {
    //variables
    this.session = {};
    this.connected = false;
    this.disconnected = false;
    this.screenShared = false;
    this.participantsList = settings.participantsList;
    this.subscribers = [];
    this.apiKey = settings.apiKey;
    this.patient = settings.patient;
    this.token = settings.token;
    this.sessionId = settings.sessionId;
    this.canvasId = settings.canvasId;
    this.signalId = Math.floor(Math.random() * 1000000 + 1);

    this.allowMultipleStreams = false;

    //DOM elements
    this.$container = document.getElementById(settings.containerElement);
    this.$mainElement = document.getElementById(settings.mainSpeakerElement);
    this.$secondarySpeaker = document.getElementById(
      settings.secondarySpeakerElement
    );

    //functions
    this.onPatientPickUp = settings.onPatientPickUp;
    this.onSessionEnded = settings.onSessionEnded;
    this.onSessionCreated = settings.onSessionCreated;
    this.onSessionStarted = settings.onSessionStarted;
    this.onErrorOccured = settings.onErrorOccured;
    this.onStreamDestroyed = settings.onStreamDestroyed;
    this.onScreenShared = settings.onScreenShared;
    this.onScreenSharedStopped = settings.onScreenSharedStopped;
    this.onWhiteboardStarted = settings.onWhiteboardStarted;
    this.onWhiteboardStopped = settings.onWhiteboardStopped;

    window.Session = this;

    this.$publisher = document.createElement("div");
    this.$publisher.classList.add("publisher-element");
    this.$secondarySpeaker.appendChild(this.$publisher);

    this.publisher = new Publisher({
      //on calling state, publisher is first display on top, like secondary speaker
      element: this.$publisher,
      settings: {
        ...settings.publisherSettings,
        afterDestroyed: () => {
          if (this.disconnected) {
            this.onSessionEnded && this.onSessionEnded("endedByClient");
          }
        },
        afterMediaStopped: (event) => {
          console.log("afterMediaStopped");
          
          if (event.target.stream) {
            if (["screen", "custom"].indexOf(event.target.stream.videoType) !== -1) {
              if (!this.allowMultipleStreams) {
                this.setElementOnTop(this.$publisher);
                this.changeVideoSource(this.previousVideoSource);
              }

              this.publisherStopShare(event);
            }
          }
        }
      },
      onAfterInit: () => {
        if (this.screenShared || this.whiteboardShared) {
          this.afterShareInitiated();
        } else {
          this.publishSession();
        }
      },
      onError: (code) => {
        this.onErrorOccured && this.onErrorOccured(code);
      }
    });

    this.createSession();
  }

  publishAudio(publish) {
    this.publisher.publishAudio(publish);
  }

  publishVideo(publish) {
    this.publisher.publishVideo(publish);
  }

  disconnectSession() {
    //avoid disconnect twice
    if (this.disconnected) return;

    this.disconnected = true;
    this.session.disconnect();
    this.session.off();
    this.publisher.data && this.publisher.data.destroy();
  }

  connectSession() {
    return new Promise((resolve, reject) => {
      this.session.connect(this.token, (error) => {
        if (error) return reject(error);
        return resolve();
      });
    });
  }

  async createSession() {
    this.session = OT.initSession(this.apiKey, this.sessionId);
    this.setSessionEvents();

    try {
      await this.connectSession();

      this.connected = true;
      this.onSessionCreated && this.onSessionCreated();

      this.publishSession();
    } catch (error) {
      this.onErrorOccured && this.onErrorOccured();
    }
  }

  publishSession(data) {
    if (!this.connected || !this.publisher.initialized) return;

    this.session.publish(data || this.publisher.data, (error) => {
      if (error) {
        console.error("publish error", error);
      }
    });
  }

  setSessionEvents() {
    this.session.on({
      signal: (event) => {
        const signalData = typeof event.data === "string" ? JSON.parse(event.data) : event.data;

        if (signalData.signalId === this.signalId) return;

        if (signalData && signalData.allowMultipleStreams) {
          this.allowMultipleStreams = true;
        }
      },
      streamCreated: (event) => {
        if (event.stream.videoType === "camera") {
          this.streamVideo(event);
        } else {
          if (!this.whiteboardShared) {
            this.streamScreen(event);
          }
        }

        this.session.signal(
          {
            data: {
              allowMultipleStreams: true,
              signalId: this.signalId
            }
          },
          function (error) {
            if (error) {
              console.error(
                "signal error (" + error.name + "): " + error.message
              );
            }
          }
        );
      },
      streamDestroyed: (event) => {
        //reasons: "clientDisconnected", "forceDisconnected", "forceUnpublished", or "networkDisconnected"
        if (event && event.stream) {

          this.subscribers = this.subscribers.filter(
            (a) => {
              if(!a.stream) return true;

              return a.stream.streamId !== event.stream.streamId;
            }
          );
        }

        if (this.subscribers && this.subscribers.length) {
          this.setElementOnCenter(
            this.subscribers[this.subscribers.length - 1].element
          );
        } else {
          this.setElementOnCenter(this.$publisher);
        }

        this.onStreamDestroyed && this.onStreamDestroyed();
      },
      sessionDisconnected: (event) => {
        this.session.off();

        if (this.tryingReconnection) {
          this.createSession();
          return;
        }

        this.onSessionEnded && this.onSessionEnded(event.reason);
      }
      // sessionReconnecting: () => {},
      // sessionReconnected: () => {},       // Adjust user interface.
      // connectionCreated: () => {},
      // connectionDestroyed: () => {},
      // audioLevelUpdated: () => {},
    });
  }

  splitConnectionData = (data) => {
    if (!data) return;

    let a = data.split("&");
    let obj = {};
    for (let index = 0; index < a.length; index++) {
      const element = a[index];
      const b = element.split("=");
      obj[b[0]] = b[1];
    }

    return obj;
  };

  addSpeakerName = (stream) => {
    if (stream.name && stream.name.length) return;

    const data = this.splitConnectionData(stream.connection.data);

    if (data && data.pid) {
      for (let index = 0; index < this.participantsList.length; index++) {
        const element = this.participantsList[index];
        if (element && element.id === data.pid) {
          stream.name = element.firstName + " " + element.lastName;
          return;
        }
      }
    }
  };

  addSubscriberImage = (subscriber, stream) => {
    try {
      let subscriberId = this.splitConnectionData(stream.connection.data).pid;
      subscriber.setStyle(
        "backgroundImageURI",
        this.participantsList.filter((p) => p.id === subscriberId)[0].picture
      );
    } catch (e) {
      //silent
    }
  };

  addSubscriber(stream, id, properties) {
    this.addSpeakerName(stream);
    let subscriber = this.session.subscribe(stream, id, properties, (error) => {
      if (error) {
        console.log("addSubscriber error", error);
      }
    });

    new ResizeObserver((resizeListObj) => {
      let resizeObj = resizeListObj[0];

      if (calcDimensions) {
        const d = calcDimensions(
          this.ratio,
          resizeObj.contentRect.width,
          resizeObj.contentRect.height - 160
        );

        const $speaker = document.getElementById(id);

        if ($speaker) {
          const $parentElement = $speaker.parentElement;
          $parentElement.style.width = d.width + "px";
          $parentElement.style.height = d.height + "px";
        }
      }
    }).observe(this.$container);

    this.addSubscriberImage(subscriber, stream);
    this.subscribers.push(subscriber);

    if (
      this.subscribers.length === 1 &&
      !this.screenShared &&
      !this.whiteboardShared
    ) {
      this.setElementOnCenter(subscriber.element);
    } else {
      this.setElementOnTop(subscriber.element);
    }
  }

  destroyPublisher() {
    const _publisher = this.getPublisher();
    _publisher.data && _publisher.data.destroy();
  }

  changeAudioSource(source) {
    this.publisher.changeAudioSource(source);
  }

  changeVideoSource(_videoSource) {
    if (_videoSource === this.publisher.settings.videoSource) return;

    Promise.all([
      OT.getUserMedia({
        videoSource: null
      })
    ]).then(([micStream]) => {
      this.publisher.changeVideoSource({
        videoSource: _videoSource,
        $container: this.$publisher,
        unpublish: () => {
          this.session.unpublish(this.publisher.data);
        },
        audioSource: micStream.getAudioTracks()[0]
      });
    });
  }

  setElementOnTop($element) {
    if (!$element) {
      debugger;
      return;
    }

    $element.classList.add("mini-publisher");
    this.$secondarySpeaker.appendChild($element);
  }

  setElementOnCenter($element) {
    if (!$element) {
      debugger;
      return;
    }

    if (this.mainSpeaker) {
      this.setElementOnTop(this.mainSpeaker);
    }

    $element.classList.remove("mini-publisher");
    this.$mainElement.appendChild($element);

    this.mainSpeaker = $element;
  }

  streamVideo(event) {
      this.onPatientPickUp();

    this.ratio =
      event.stream.videoDimensions.width / event.stream.videoDimensions.height;

    let subscriberProperties;
    subscriberProperties = {
      insertMode: "append",
      width: "100%",
      height: "100%",
      showControls: true,
      style: {
        nameDisplayMode: "on",
        buttonDisplayMode: "off",
        videoDisabledDisplayMode: "off",
        audioLevelDisplayMode: "off"
      }
    };

    const dimensions = calcDimensions(
      this.ratio,
      this.$container.offsetWidth,
      this.$container.offsetHeight - 160
    );

    const parentElementStyle = this.$mainElement.parentElement.style;
    parentElementStyle.width = dimensions.width + "px";
    parentElementStyle.height = dimensions.height + "px";
    parentElementStyle.margin = "0 auto";

    const div = document.createElement("div");
    div.classList.add("test-subscriber");
    div.id = "subscriber_" + this.subscribers ? this.subscribers.length : 0;

    this.addSubscriber(event.stream, div.id, subscriberProperties);

    if (!this.sessionStarted) {
      this.onSessionStarted && this.onSessionStarted();
      this.sessionStarted = true;
    }
  }

  streamScreen(event) {
    const subscriberProperties = {
      insertMode: "append",
      width: "100%",
      height: "100%",
      style: {
        buttonDisplayMode: "off",
        videoDisabledDisplayMode: "off",
        audioLevelDisplayMode: "off"
      }
    };

    const div = document.createElement("div");
    div.id = "streamed_screen_1";

    window.stream = event.stream;

    const subscriber = this.session.subscribe(
      event.stream,
      div,
      subscriberProperties
    );

    this.setElementOnCenter(subscriber.element);

    this.subscribers.push(subscriber);
    this.screenShared = true;
  }

  shareScreen() {
    OT.checkScreenSharingCapability((response) => {
      if (!response.supported || response.extensionRegistered === false) {
        // This browser does not support screen sharing.
      } else if (response.extensionInstalled === false) {
        // Prompt to install the extension.
      } else {
        if (this.allowMultipleStreams) {
          this.$screenContainer = document.createElement("div");
          this.$screenContainer.id = "streamed_screen_2";

          this.screenPublisher = new Publisher({
            element: this.$screenContainer,
            settings: {
              videoSource: "screen",
              mirror: false,
              afterMediaStopped: (event) => {
                if (
                  event.target.stream &&
                  event.target.stream.videoType === "screen"
                ) {
                  this.publisherStopShare(event);
                }
              }
            },
            onAfterInit: () => {
              this.screenShared = true;
              this.setElementOnCenter(this.$screenContainer);
              this.afterShareInitiated();
            }
          });
        } else {
          this.screenShared = true;
          this.publisher.disableMirror();
          this.previousVideoSource = this.publisher.getVideoSource();
          this.previousAudioSource = this.publisher.getAudioSource();

          this.setElementOnCenter(this.$publisher);

          this.changeVideoSource("screen");
        }
      }
    });
  }

  getPublisher() {
    if (this.allowMultipleStreams) {
      if (this.screenShared) {
        return this.screenPublisher;
      }
      if (this.whiteboardShared) {
        return this.whiteboardPublisher;
      }
    }

    return this.publisher;
  }

  publisherStopShare(event) {
    this.subscribers = this.subscribers.filter(
      (a) => a && !(a instanceof Publisher)
    );

    if (this.subscribers && this.subscribers.length) {
      this.setElementOnCenter(
        this.subscribers[this.subscribers.length - 1].element
      );
    } else {
      this.setElementOnCenter(this.$publisher);
    }

    if (this.screenShared) {
      this.$screenContainer && this.$screenContainer.remove();
      this.$screenContainer = undefined;
    } else if (this.whiteboardShared) {
      this.$whiteboardPublisher && this.$whiteboardPublisher.remove();
      this.$whiteboardPublisher = undefined;
    }

    this.afterShareStopped(event && event.target && event.target.stream);
  }

  stopShareScreen() {
    if (this.allowMultipleStreams) {
      const pscreen = this.subscribers.find((a) => a instanceof Publisher);

      if (pscreen && pscreen.data) {
        pscreen.data.destroy();
      }
    } else {
      this.setElementOnTop(this.$publisher);
      this.changeVideoSource(this.previousVideoSource);
    }

    this.publisherStopShare();
  }

  afterShareStopped(stream) {
    stream && stream.destroy();

    if (this.screenShared) {
      this.screenShared = false;
      this.onScreenSharedStopped();
    } else if (this.whiteboardShared) {
      this.whiteboardShared = false;
      this.onWhiteboardStopped();
    }
  }

  whiteBoard($ref) {
    if (!$ref) {
      debugger;
      return;
    }

    this.$whiteboardPublisher = document.createElement("div");
    this.$whiteboardPublisher.id = "whiteboard_container";
    this.$whiteboardPublisher.style.display = "none";

    let videoSource;
    try {
      videoSource = $ref.captureStream(24).getVideoTracks()[0];
    } catch (error) {
      this.onErrorOccured("canvas");
      return;
    }

    if (this.allowMultipleStreams) {
      this.setElementOnTop(this.mainSpeaker);

      this.whiteboardPublisher = new Publisher({
        element: this.$whiteboardPublisher,
        settings: {
          videoSource,
          publishAudio: false,
          mirror: false,
          afterMediaStopped: (event) => {
            if (
              event.target.stream &&
              event.target.stream.videoType === "custom"
            ) {
              this.publisherStopShare(event);
            }
          }
        },
        onAfterInit: () => {
          this.whiteboardShared = true;
          this.afterShareInitiated();
        }
      });
    } else {
      this.whiteboardShared = true;
      this.publisher.disableMirror();
      this.previousVideoSource = this.publisher.getVideoSource();
      this.previousAudioSource = this.publisher.getAudioSource();

      this.setElementOnCenter(this.$publisher);

      this.changeVideoSource(videoSource);
    }

    // this.whiteboardPublisher.data.on('destroyed', () => {
    //   clearInterval(interval);
    // });
  }

  afterShareInitiated() {
    if (this.screenShared) this.onScreenShared && this.onScreenShared();
    else if (this.whiteboardShared)
      this.onWhiteboardStarted && this.onWhiteboardStarted();

    const _publisher = this.getPublisher();

    if (this.allowMultipleStreams) {
      this.publishSession(_publisher.data);

      this.subscribers.push(_publisher);
    } else {
      this.publishSession();
    }
  }

  // speakerDetection(subscriber, startTalking, stopTalking) {
  //   var activity = null;
  //   subscriber.on({
  //     audioLevelUpdated: (event) => {
  //       var now = Date.now();
  //       if (event.audioLevel > 0.2) {
  //         if (!activity) {
  //           activity = { timestamp: now, talking: false };
  //         } else if (activity.talking) {
  //           activity.timestamp = now;
  //         } else if (now - activity.timestamp > 1000) {
  //           // detected audio activity for more than 1s
  //           // for the first time.
  //           activity.talking = true;
  //           if (typeof startTalking === "function") {
  //             startTalking(event);
  //           }
  //         }
  //       } else if (activity && now - activity.timestamp > 3000) {
  //         // detected low audio activity for more than 3s
  //         if (activity.talking) {
  //           if (typeof stopTalking === "function") {
  //             stopTalking();
  //           }
  //         }
  //         activity = null;
  //       }
  //     },
  //   });
  // }
}
