import React from "react";
import Popup from "./common/Popup";
import ArmchairIcon from "../images/armchair.svg";

const SessionNotStarted = () => {
  return (
    <Popup title="Oops..." buttons={[{ text: "OK", onClick: () => {} }]}>
      <div
        style={{
          padding: "30px",
          textAlign: "center",
          display: "grid",
          rowGap: "30px",
        }}
      >
        <div>
          <ArmchairIcon></ArmchairIcon>
        </div>
        <div>
          Your meeting will begin in more than hour from now. You can connect
          again a few minutes before your meeting begins.
        </div>
      </div>
    </Popup>
  );
};

SessionNotStarted.propTypes = {};

export default SessionNotStarted;
