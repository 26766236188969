import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import defaultAvatar from "../../images/user_male.jpeg";

const ChatDate = ({ date }) => {
  return (
    <div className="text-between-lines chat-date">
      <span>{moment(date).format("dddd, D MMMM YYYY").toUpperCase()}</span>
    </div>
  );
};

ChatDate.propTypes = {
  date: PropTypes.object,
};

const ChatMessage = ({
  message,
  index,
  messagesArr,
  ownMessage,
  patientMessage,
}) => {
  const previousMessage = index && messagesArr[index - 1];

  const displayIconAndDate =
    !index ||
    message.from !== previousMessage.from ||
    moment.duration(moment(message.date).diff(previousMessage.date)).minutes() >
      4;

  let messageClass = "message-container";
  if (!displayIconAndDate) {
    messageClass += " no-date-message";
  }
  if (ownMessage) {
    messageClass += " own-message";
  } else if (patientMessage) {
    messageClass += " patient-message";
  }

  return (
    <div className={messageClass}>
      {(!index ||
        moment(message.date).format("YYYYDDD") !==
          moment(messagesArr[index - 1].date).format("YYYYDDD")) && (
        <ChatDate date={message.date}></ChatDate>
      )}
      {!message.read && (!previousMessage || previousMessage.read) && (
        <div className="text-between-lines unread-message">
          <span>NEW MESSAGES</span>
        </div>
      )}
      <div className="message">
        <div className="message-icon">
          {displayIconAndDate && !ownMessage && (
            <img height="32px" width="32px" src={message.icon || defaultAvatar} />
          )}
        </div>
        <div className="message-date-and-content">
          <div className="message-date">
            {displayIconAndDate &&
              (!ownMessage ? message.from + ", " : "") +
                moment(message.date).format("H:mma")}
          </div>
          <div className="message-content">{message.text}</div>
        </div>
      </div>
    </div>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.object,
  index: PropTypes.number,
  messagesArr: PropTypes.array,
  ownMessage: PropTypes.bool,
  patientMessage: PropTypes.bool,
};

export default ChatMessage;
