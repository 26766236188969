import { configureStore } from '@reduxjs/toolkit'
import chatReducer from './slices/chatSlice';
import videoReducer from './slices/videoSlice';
import globalReducer from './slices/globalSlice';

const store = configureStore({
  reducer: {
    chat: chatReducer,
    video: videoReducer,
    global: globalReducer
  },
})

export default store