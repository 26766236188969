import React, { useEffect } from "react";
import Popup from "./common/Popup";
import { useSelector } from "react-redux";
import { alertMessages as messages } from "./utilities/index";

const ErrorPage = () => {
  const {title, message} = useSelector(state => state.global);
  useEffect(() => {
    try {
      window.myPublisher.data.destroy();
    } catch (error) {
      //
    }
  }, []);

  return (
    <Popup hideCloseBtn={true} title={title || messages.error.title}>
      {message || messages.error.message}
    </Popup>
  );
};

export default ErrorPage;
