import React, { useEffect } from "react";
import Popup from "./common/Popup";
import { clearStorage } from "../api/storageConfig";
import { leaveEndPage } from "../redux/slices/globalSlice";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "./react-redux-hooks";

const EndSession = () => {
  const {title, message} = useSelector(state => state.global);
  const {participant, sessionTerminated} = useSelector(state => state.video);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      window.myPublisher.data.destroy();
    } catch (error) {
      //
    }
  }, []);

  const history = useHistory();

  let ownMessage = undefined;
  let ownTitle = undefined;

  if (!sessionTerminated) {
    ownMessage = "You have left the session.";
    ownTitle = "Left session";
  }

  let btns = [];

  if (participant && participant.role === "organizer") {
    btns.push({
      text: "Close",
      type: "primary",
      onClick: () => {
        window.close();
      },
    });

    if (sessionTerminated) {
      clearStorage();
    }
  }

  if (!sessionTerminated) {
    btns.push({
      text: "Rejoin session",
      type: "primary",
      onClick: () => {
        dispatch(leaveEndPage());
        history.push("/telehealth/meetingRoom");
      },
    });
  }

  return (
    <Popup
      buttons={btns.length ? btns : undefined}
      hideCloseBtn={true}
      title={ownTitle || title || "Session Ended"}
    >
      {ownMessage || message || "Session has been ended"}
    </Popup>
  );
};

export default EndSession;
