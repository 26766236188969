import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Popup from "../common/Popup";
import defaultAvatar from "./../../images/user_male.jpeg";
import "./style.css";
import Sentiment_1 from "../../images/sentiment_1.svg";
import Sentiment_2 from "../../images/sentiment_2.svg";
import Sentiment_3 from "../../images/sentiment_3.svg";
import Sentiment_4 from "../../images/sentiment_4.svg";
import Sentiment_5 from "../../images/sentiment_5.svg";
import FinishImg from "../../images/streamline-video-conference.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { loadFeedbackConfig } from "../../redux/slices/videoSlice";
import { sendFeedback } from "../../api/videoAPI";

const pageEnum = {
  smiley: "smiley",
  tag: "tag",
  note: "note",
  finish: "finish"
};

const states = [
  {
    icon: <Sentiment_1 />,
    reaction1: "We're very sorry to hear that.",
    reaction2: "What could we have done better?",
    finish1: "Thank you!",
    finish2: "We appreciate and welcome opportunities to improve our services."
  },
  {
    icon: <Sentiment_2 />,
    reaction1: "We're very sorry to hear that.",
    reaction2: "What could we have done better?",
    finish1: "Thank you!",
    finish2: "We appreciate and welcome opportunities to improve our services."
  },
  {
    icon: <Sentiment_3 />,
    reaction1: "Thank you fore the feedback",
    reaction2: "Where could we improve?",
    finish1: "Thank you!",
    finish2: "We appreciate and welcome opportunities to improve our services."
  },
  {
    icon: <Sentiment_4 />,
    reaction1: "Thanks! We would love to learn more:",
    reaction2: "Where could we improve?",
    finish1: "Thank you!",
    finish2: "We appreciate and welcome opportunities to improve our services."
  },
  {
    icon: <Sentiment_5 />,
    reaction1: "Fantastic!",
    reaction2: "What went wrong?",
    finish1: "Fantastic!",
    finish2: "Thank you for your feedback"
  }
];

const PatientFeeback = () => {
  const dispatch = useDispatch();
  const { participants, feedback } = useSelector((state) => state.video);

  useEffect(() => {
    if (!feedback) {
      dispatch(loadFeedbackConfig());
    }

    try {
      window.myPublisher.data.destroy();
    } catch (error) {
      //
    }
  }, []);

  const [selectedSmiley, setSelectedSmiley] = useState();
  const [selectedTags, setSelectedTags] = useState({});
  const [selectedPage, setSelectedPage] = useState(pageEnum.smiley);
  const [disabledNextBtn, setDisabledNextBtn] = useState(true);
  const [noteText, setNoteText] = useState("");

  const handleNextStep = () => {
    if (selectedPage === pageEnum.smiley) {
      if (selectedSmiley === 4) {
        handleFinishFeeback()
      } else {
        setSelectedPage(pageEnum.tag);
      }
    } else if (selectedPage === pageEnum.tag) {
      setSelectedPage(pageEnum.note);
    }
    else if(selectedPage === pageEnum.note) {
        handleFinishFeeback()
    }
  };

  const handleFinishFeeback = () => {
    let tags = [];
    if (selectedPage === pageEnum.note) {
      feedback.tags.forEach(element => {
        if(selectedTags && selectedTags[element.name])
        tags.push(element);        
      });
      
      if(noteText) {
        tags.push({
          type: "other",
          name: noteText,
          freetext: true
        });
      }
    }

    sendFeedback(selectedSmiley + 1, tags.length ? tags : undefined);
    setSelectedPage(pageEnum.finish);
  }

  const handleBackStep = () => {
    if (selectedPage === pageEnum.tag) {
      setSelectedPage(pageEnum.smiley);
    } else if (selectedPage === pageEnum.note) {
      setSelectedPage(pageEnum.tag);
    }
  };

  const FinishContent = () => {
    const textContent1 = states[selectedSmiley].finish1;
    const textContent2 = states[selectedSmiley].finish2;

    return (
      <div className="finish-content">
        <div className="text">
          <div className="bold" style={{ fontSize: "22px" }}>
            {textContent1}
          </div>
          <div className="bold" style={{ fontSize: "16px" }}>
            {textContent2}
          </div>
        </div>
        <div className="image">
          <FinishImg />
        </div>
      </div>
    );
  };

  return (
    <Popup
      bodyClass="feedback-popup"
      title="Session End"
      options={{ hideFooter: selectedPage === pageEnum.finish }}
      hideCloseBtn
      buttons={[
        {
          text: "Back",
          type: "link",
          invisible:
            selectedPage === pageEnum.smiley ||
            selectedPage === pageEnum.finish,
          onClick: () => {
            handleBackStep();
          }
        },
        {
          text: selectedPage !== pageEnum.note ? "Next" : "Done",
          type: "primary",
          disable: disabledNextBtn,
          invisible: selectedPage === pageEnum.finish,
          onClick: () => {
            handleNextStep();
          }
        }
      ]}
    >
      {selectedPage !== pageEnum.finish && (
        <FeedbackHeader participants={participants} />
      )}
      <div className="feedback-content">
        {selectedPage === pageEnum.smiley && (
          <SmileyContent
            selectedSmiley={selectedSmiley}
            handleChange={(i) => {
              setSelectedSmiley(i);
              setDisabledNextBtn(false);
            }}
          />
        )}
        {selectedPage === pageEnum.tag && (
          <TagContent
            textContent1={states[selectedSmiley].reaction1}
            textContent2={states[selectedSmiley].reaction2}
            tags={feedback.tags}
            selectedTags={selectedTags}
            handleChange={setSelectedTags}
          />
        )}
        {selectedPage === pageEnum.note && (
          <NoteContent handleChange={setNoteText} note={noteText} />
        )}
        {selectedPage === pageEnum.finish && <FinishContent />}
      </div>
    </Popup>
  );
};

const FeedbackHeader = ({ participants }) => {
  const data = participants.find(
    (participant) => participant.role === "organizer"
  );

  if(!data) return <div></div>;
  
  const date = new Date();

  return (
    <div className="feedback-header">
      <div className="provider-picture">
        <img
          src={data.picture || defaultAvatar}
          height="34px"
          width="34px"
          alt="avatar"
        />
      </div>
      <div className="provider-data">
        <div className="session-date">
          {moment(date).format("MM/DD/YYYY").toUpperCase()}
        </div>
        <div className="session-with">
          Video Session with {data.firstName + " " + data.lastName}
        </div>
      </div>
    </div>
  );
};

const TagContent = ({
  textContent1,
  textContent2,
  tags,
  selectedTags,
  handleChange
}) => {
  return (
    <div className="tag-content">
      <div className="tag-content-1 bold" style={{ fontSize: "16px" }}>
        {textContent1}
      </div>
      <div className="tag-content-2 bold" style={{ fontSize: "22px" }}>
        {textContent2}
      </div>
      <div className="tags">
        {tags &&
          tags.map((tag, i) => {
            return (
              <span
                className={
                  "feedback-tag " + (selectedTags[tag.name] ? "selected" : "")
                }
                onClick={() => {
                  handleChange({
                    ...selectedTags,
                    [tag.name]: !selectedTags[tag.name]
                  });
                }}
                key={i}
              >
                {tag.name}
              </span>
            );
          })}
      </div>
    </div>
  );
};

const NoteContent = ({ note, handleChange }) => {
  return (
    <div className="tag-content">
      <div className="bold" style={{ fontSize: "16px" }}>
        Thank you for letting us know.
      </div>
      <div className="bold" style={{ fontSize: "22px", width: "460px" }}>
        A detailed feedback would allow us to address the problem:{" "}
      </div>
      <textarea
        placeholder="Add a note for our team"
        className="note-area"
        value={note}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
    </div>
  );
};

const SmileyContent = ({ selectedSmiley, handleChange }) => {
  return (
    <div className="bold">
      <div style={{ fontSize: "16px" }}>Let us know how we are doing:</div>
      <div style={{ fontSize: "22px" }}>How did the session go?</div>
      <div className="states-smiley">
        {states.map((state, i) => {
          return (
            <span
              key={i}
              className={
                "smiley-icon " + (selectedSmiley === i ? "selected" : "")
              }
              onClick={() => {
                handleChange(i);
              }}
            >
              {state.icon}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default PatientFeeback;

FeedbackHeader.propTypes = {
  participants: PropTypes.array
};

NoteContent.propTypes = {
  note: PropTypes.string,
  handleChange: PropTypes.func,
};

SmileyContent.propTypes = {
  selectedSmiley: PropTypes.string,
  handleChange: PropTypes.func,
};

TagContent.propTypes = {
  textContent1: PropTypes.string,
  textContent2: PropTypes.string,
  tags: PropTypes.array,
  selectedTags: PropTypes.array,
  handleChange: PropTypes.func
};