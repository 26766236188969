export async function handleResponse(response) {
  if (response.status === 200 || response.status === 204)
    return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

export function handleError(error) {
  //TODO: 500 => something was wrong
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}
