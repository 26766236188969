import React from "react";
import {
  setRoomId,
  setSessionId,
  setToken,
  setParticipantId
} from "../../api/storageConfig";

export const getErrorDetails = (message) => {
  switch (message) {
    case "Missing authentication":
      return {
        title: "Missing authentication",
        message: "You need to be logged in to access this page.",
        type: "error"
      };
    case "participant_already_connected":
      return {
        title: "Already connected",
        message: "You are already connected to the session in another tab.",
        type: "error"
      };
    case "session_expired":
      return {
        title: "Session expired",
        message: "Session has been expired.",
        type: "end",
        endSession: true
      };
    case "session_not_started_or_activated":
      return {
        title: "Session not started",
        message: <><div>Session has not been started or activated.</div><div>Please wait for the provider to start the session and then rejoin.</div></>,
        type: "end",
        endSession: true
      };
    case "session_already_terminated":
      return {
        title: "Session terminated",
        message: "Session has been terminated.",
        type: "end",
        endSession: true
      };
    case "ctm_not_in_care_team":
      return {
        title: "Error",
        message:
          "You need to belong to patient's care team in order to initiate video session.",
        type: "error"
      };
    case "organizer_already_added":
      return {
        title: "Error",
        message: "Another provider already in session.",
        type: "error"
      };
    default:
      return {
        title: "Error occurred",
        message: "Error occurred on page.",
        type: "error"
      };
  }
};

export const isIOS =
  !!navigator.platform && /iPad|iPhone|iPod|Mac/.test(navigator.platform);

export const getRoomId = () => {
  try {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);

    return [urlParams.get('rid'), urlParams.get('sid'), urlParams.get('pid'), urlParams.get('t')] || false;
  } catch (error) {
    false;
  }
};

export const setParamsStorage = () => {
  try {
    const query = location.search.split("?");
    const rs = decodeURI(query[1]);
    if (!rs) return;

    const params = JSON.parse(rs);
    setRoomId(params.roomId);
    setSessionId(params.sessionId);
    setToken(params.accessToken);
    setParticipantId(params.participantId);
    window.history.replaceState({}, document.title, "/telehealth/waitingRoom");
    return params;
  } catch (error) {
    false;
  }
};

export const getParticipantById = (participants) => {
  let itn = {};

  for (let index = 0; index < participants.length; index++) {
    const element = participants[index];
    itn[element.id] = element.firstName + " " + element.lastName;
  }

  return itn;
};

export const calcDimensions = (ratio, width, height) => {
  //set width/height from container
  const currentRatio = width / height;

  if (currentRatio > ratio) {
    const factor = ratio * (height / width);
    return {
      width: factor * width,
      height: height,
    };
  } else if (currentRatio < ratio) {
    const factor = (1 / ratio) * (width / height);

    return {
      width: width,
      height: factor * height,
    };
  } else {
    return { width: width, height: height };
  }
};

export const alertMessages = {
  end: { title: "Session ended", message: "Session has been ended." },
  error: { title: "Error occured", message: "Error occurred on page." },
  invalid: { title: "Invalid link", message: "Link is invalid." }
};
