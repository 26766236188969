import React from "react";
import "./style.css";
import PropTypes from "prop-types";

const VideoMessage = ({ message }) => {
  return <div className="video-message-container">{message}</div>;
};

VideoMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default VideoMessage;
