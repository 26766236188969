import React from "react";
import TriangleIcon from "../../images/triangle_shape.svg";
import CircleIcon from "../../images/circle_shape.svg";
import RectIcon from "../../images/rectangle_shape.svg";
import PentaIcon from "../../images/pentagon_shape.svg";
import DiamondIcon from "../../images/diamond_shape.svg";
import HexaIcon from "../../images/hexagon_shape.svg";
import SquareIcon from "../../images/square_shape.svg";
import OpacityPencilIcon from "../../images/opacity_pencil.svg";
import NormalPencilIcon from "../../images/normal_pencil.svg";
import ErasePencilIcon from "../../images/erase_pencil.svg";
import BlackColorIcon from "../../images/black_update.svg";
import BlueColorIcon from "../../images/blue_update.svg";
import GreenColorIcon from "../../images/green_update.svg";
import PurpleColorIcon from "../../images/purple_update.svg";
import RedColorIcon from "../../images/red_update.svg";
import OrangeColorIcon from "../../images/yellow_update.svg";
import ArrowStickerIcon from "../../images/arrow_sticker_small.svg";
import CheckStickerIcon from "../../images/check_sticker_small.svg";
import HeartStickerIcon from "../../images/heart_sticker_small.svg";
import SignStickerIcon from "../../images/sign_sticker_small.svg";
import StarStickerIcon from "../../images/star_sticker_small.svg";
import UpStickerIcon from "../../images/up_sticker_small.svg";
import arrowSticker from "../../images/arrow_sticker";
import checkSticker from "../../images/check_sticker";
import heartSticker from "../../images/heart_sticker";
import signSticker from "../../images/sign_sticker";
import starSticker from "../../images/star_sticker";
import upSticker from "../../images/up_sticker";

// export const CANVAS_WIDTH = document.getElementById("main-speaker-container").style.width;
// export const CANVAS_HEIGHT = document.getElementById("main-speaker-container").style.height;

export const ShapeTypes = {
  rect: "rect",
  square: "square",
  circle: "circle",
  triangle: "triangle",
  pentagone: "pentagone",
  diamond: "diamond",
  hexagon: "hexagon",
  text: "text",
  pen: "pen",
  stamp: "stamp",
  image: "image",
};

export const MENU_STATE = {
  cursor: "cursor",
  text: "Text",
  shape: "Shape",
  draw: "Draw",
  stamp: "Stamp",
  image: "Cursor",
};

export const ALIGN_OPTIONS = {
  left: "left",
  center: "center",
  right: "right",
};

export const PAINT_OPTIONS = {
  stroke: "stroke",
  fill: "fill",
};

export const FONT_OPTIONS = {
  small: "small",
  default: "default",
  big: "big",
};

export const SHAPE_ICONS = {
  triangle: <TriangleIcon />,
  circle: <CircleIcon />,
  pentagone: <PentaIcon />,
  square: <SquareIcon />,
  rect: <RectIcon />,
  hexagon: <HexaIcon />,
  // diamond: <DiamondIcon />,
};

export const TOOLBAR_KEYS = {
  color: "color",
  text: "text",
  pencil: "pencil",
  shape: "shape",
  stamp: "stamp",
  image: "image",
};

export const TOOLBAR_COLOR = {
  orange: "orange",
  green: "green",
  blue: "blue",
  red: "red",
  gray: "gray",
  purple: "purple",
};

export const WHITEBOARD_COLOR = {
  orange: "#ffb200",
  green: "#39c696",
  blue: "#00a3ff",
  red: "#ef1414",
  gray: "#54585d",
  purple: "#a87df3",
};

export const COLOR_ICONS = {
  orange: <OrangeColorIcon />,
  green: <GreenColorIcon />,
  blue: <BlueColorIcon />,
  red: <RedColorIcon />,
  gray: <BlackColorIcon />,
  purple: <PurpleColorIcon />,
};

export const PENCIL_OPTIONS = {
  normal: "normal",
  opacity: "opacity",
  erase: "erase",
};

export const PENCIL_CURSOR = {
  normal: "pencil-cursor",
  opacity: "brush-cursor",
  erase: "eraser-cursor",
};

export const PENCIL_ICONS = {
  normal: <NormalPencilIcon />,
  opacity: <OpacityPencilIcon />,
  erase: <ErasePencilIcon />,
};

export const STAMP_OPTIONS = {
  up: "up",
  heart: "heart",
  star: "star",
  check: "check",
  sign: "sign",
  arrow: "arrow",
};

export const STAMP_ICONS = {
  arrow: <ArrowStickerIcon />,
  check: <CheckStickerIcon />,
  heart: <HeartStickerIcon />,
  sign: <SignStickerIcon />,
  star: <StarStickerIcon />,
  up: <UpStickerIcon />,
};

export const STAMP_DATA = {
  arrow: arrowSticker,
  check: checkSticker,
  heart: heartSticker,
  sign: signSticker,
  star: starSticker,
  up: upSticker,
}

export const DEFAULT_SELECTED_OPTIONS = {
  color: "gray",
  shape: "triangle",
};

export const FONT_SIZE_VALUES = [8,10,11,12,14,18,24,30,36,48,60,72];
export const DEFAULT_FONT_SIZE = 18;
export const DEFAULT_COLOR = TOOLBAR_COLOR.gray;