import React, { useState, useEffect } from "react";
import Popup from "../common/Popup";
import PropTypes from "prop-types";
import OptionsMenu from "../common/OptionsMenu";
import Button from "../common/Button";

export default function LoginCode({
  displayErrorMessage,
  handleCode,
  userPhone,
  backToPhone,
  sendCodeBack
}) {
  const [code, setCode] = useState("");
  const [sendAgain, setSendAgain] = useState(false);
  const [timer, setTimer] = useState(0);
  const [emptyField, setEmptyField] = useState(false);

  const sendCode = () => {
    if(code && code.length && code.trim().length) {
      handleCode(code);
    }
    else {
      setEmptyField(true);
    }
  }

  useEffect(() => {
    if (!sendAgain) return;
    if (timer === 0) {
      setSendAgain(false);
    }

    setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
  }, [timer]);

  return (
    <div>
      <Popup
        hideCloseBtn={true}
        title="Log In"
        buttons={[
          {
            text: "Back",
            type: "link",
            style: { position: "absolute", left: "0px" },
            onClick: () => {
              backToPhone();
            }
          },
          {
            text: sendAgain ? timer + " seconds" : "",
            type: "primary",
            disable: sendAgain,
            component: !sendAgain && (
              <OptionsMenu
                positionRelativeTo="app"
                items={[
                  {
                    title: "Send via SMS",
                    onclick: () => {
                      sendCodeBack("sms");
                      setSendAgain(true);
                      setTimer(30);
                    }
                  },
                  {
                    title: "Send by a phone call",
                    onclick: () => {
                      sendCodeBack("call");
                      setSendAgain(true);
                      setTimer(30);
                    }
                  }
                ]}
                openOn="top"
                Icon={
                  <Button
                    text={
                      <>
                        Send the code again
                        <span
                          className="arrow-down"
                          style={{ borderColor: "#ffffff" }}
                        ></span>
                      </>
                    }
                    type="primary"
                    onClick={() => {}}
                  />
                }
              />
            ),
            onClick: () => {
              sendCodeBack();
              setSendAgain(true);
              setTimer(30);
            }
          },
          {
            text: "Enter",
            id: "login_enter",
            type: "primary",
            onClick: () => {
              sendCode(code);
            }
          }
        ]}
      >
        <div className="login-container">
          <div>
            A 4-digit code was sent to <i>{userPhone}</i>.
            <br /> Please enter the code you&apos;ve received:
          </div>
          <input
            autoFocus
            value={code}
            onChange={(e) => {              
              setEmptyField(false);
              setCode(e.target.value);
            }}
            type="number"
            placeholder="Ex. 1234"
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                //press enter is like click on "Next"
                event.preventDefault();
                sendCode(code);
              }
            }}
          />
          {displayErrorMessage && (
            <div className="error-message">
              You have entered an invalid code. Please try again.
            </div>
          )}
          {emptyField && !displayErrorMessage && (
            <div className="error-message">
              Please fill code
            </div>
          )}
        </div>
      </Popup>
    </div>
  );
}

LoginCode.propTypes = {
  displayErrorMessage: PropTypes.bool,
  handleCode: PropTypes.func,
  userPhone: PropTypes.string,
  backToPhone: PropTypes.func,
  sendCodeBack: PropTypes.func
};
