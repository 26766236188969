import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./components/App";
import "./index.css";
import { Provider as ReduxProvider } from "react-redux";
import PageNotFound from "../src/components/PageNotFound";
import store from "./redux/configureStore";

const VideoAdaptor = () => {
  return (
    <ReduxProvider store={store}>
      <Router>
        <Switch>
          <Route path="/telehealth">
            <App />
          </Route>
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
    </ReduxProvider>
  );
};

export default VideoAdaptor;
