import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import Popup from "../common/Popup";
import PropTypes from "prop-types";
import InputSourcesList from "./../InputSourcesList";
import { toggleSettings } from "../../redux/slices/videoSlice";

const VideoSettings = ({
  handleSave
}) => {
  const dispatch = useDispatch();
  const {videoSource, audioSource} = useSelector(state => state.video);
  
  const [selectedVideoSource, setSelectedVideoSource] = useState(videoSource);
  const [selectedAudioSource, setSelectedAudioSource] = useState(audioSource);
  const [numOfPids] = useState(20);
  let javascriptNode;

  function colorPids(vol) {
    let all_pids = document.querySelectorAll(".pid");
    let amout_of_pids = Math.round(vol / 4);
    let elem_range = Array.prototype.slice
      .call(all_pids)
      .slice(0, amout_of_pids);
    for (let i = 0; i < all_pids.length; i++) {
      all_pids[i].style.backgroundColor = "#DADCDF";
    }
    let max = Math.min(all_pids.length, elem_range.length);
    for (let y = 0; y < max; y++) {
      elem_range[y].style.backgroundColor = "#31A7AB";
    }
  }

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = function () {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          var values = 0;

          var length = array.length;
          for (var i = 0; i < length; i++) {
            values += array[i];
          }

          var average = values / length;
          colorPids(average);
        };
      })
      .catch(function (err) {
        /* handle the error */
      });

    return () => {
      if(javascriptNode) {
        javascriptNode.onaudioprocess = undefined;
      }
    };
  }, []);

  return (
    <Popup
      hideCloseBtn={true}
      type="medium"
      title="Settings"
      buttons={[
        {
          text: "Cancel",
          type: "link",
          onClick: () => {
            dispatch(toggleSettings());
          },
        },
        {
          text: "Save",
          onClick: () => {
            handleSave(selectedAudioSource, selectedVideoSource);
          },
        },
      ]}
    >
      <div id="settings-popup">
        <table>
          <tbody>
            <tr className="settings-option">
              <td className="settings-name">Camera</td>
              <td className="settings-param">
                <InputSourcesList
                  input="videoInput"
                  selectedSource={selectedVideoSource}
                  handleChange={(videoInput) => {
                    setSelectedVideoSource(videoInput);
                  }}
                ></InputSourcesList>
              </td>
            </tr>
            <tr>
              <td className="settings-name" style={{ padding: "15px" }}>
                Microphone
              </td>
              <td className="settings-param" style={{ padding: "15px" }}>
                <InputSourcesList
                  input="audioInput"
                  selectedSource={selectedAudioSource}
                  handleChange={(audioInput) => {
                    setSelectedAudioSource(audioInput);
                  }}
                ></InputSourcesList>
              </td>
            </tr>
            <tr
              className="settings-option"
              style={{ marginBottom: "1px solid #dadcdf" }}
            >
              <td></td>
              <td className="settings-param">
                <div className="micro-volume">
                  {Array.from({ length: numOfPids }, (value, index) => (
                    <div className="pid" key={index}></div>
                  ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Popup>
  );
};

VideoSettings.propTypes = {
  handleSave: PropTypes.func,
};

export default VideoSettings;
