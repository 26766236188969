import { createSlice } from "@reduxjs/toolkit";
import * as chatAPI from "../../api/chatAPI";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chatMessages: [],
    unreadMessage: false,
  },
  reducers: {
    loadChatMessagesSuccess(state, action) {
      state.chatMessages = action.payload;
    },
    setAllMessagesAsRead(state, action) {
      state.unreadMessage = false;
      state.chatMessages = state.chatMessages.map((m) => {
        return { ...m, read: true };
      });
    },
    addChatMessage(state, action) {
      state.chatMessages.push(action.payload);
    },
    newUnreadMessage(state, action) {
      state.unreadMessage = true;
    },
    loadChatMessageSuccess(state, action) {
      state.chatMessages = action.payload;
    },
  },
});

export default chatSlice.reducer;

export const {
  setAllMessagesAsRead,
  loadChatMessagesSuccess,
  newUnreadMessage,
  loadChatMessageSuccess,
  addChatMessage,
} = chatSlice.actions;

export const getChatMessages = (sessionId, roomId) => async (dispatch) => {
const messages = await chatAPI.getChatMessages(sessionId, roomId);
  let chatMessages = messages.reverse().map((m) => {
    return {
      icon: m.picture && encodeURI(m.picture),
      from: m.firstName + " " + m.lastName,
      fromId: m.senderId,
      date: new Date(m.createdAt),
      text: m.body,
      read: true,
    };
  });

  dispatch(loadChatMessagesSuccess(chatMessages));
};
