import React from "react";
import Popup from "../common/Popup";
import SessionLink from "../SessionLink";
import PropTypes from "prop-types";
import ParticipantsList from "../WaitingRoom/ParticipantsList";
import { useSelector } from "react-redux";

const CheckList = ({ list, handleCheck }) => {
  return (
    <div className="check-list">
      {list &&
        list.map((l, index) => {
          return (
            <div
              key={index}
              className={"check-list-element" + (l.selected ? " active" : "")}
            >
              <input
                type="checkbox"
                defaultChecked={l.selected}
                onChange={(e) => {
                  handleCheck({
                    name: l.name,
                    selected: e.target.checked,
                  });
                }}
              />
              <img src={l.avatar} alt="avatar" />
              <div className="check-list-element-name">{l.name}</div>
            </div>
          );
        })}
    </div>
  );
};

CheckList.propTypes = {
  list: PropTypes.array,
  handleCheck: PropTypes.func,
};

const AddParticipant = ({ handleSave, handleClose }) => {
  const {participant} = useSelector(state => state.video);

  return (
    <Popup
      bodyClass="participants-body"
      title="Participants"
      hideCloseBtn={true}
      onClosed={handleClose}
      buttons={[
        {
          text: "OK",
          onClick: handleSave,
        },
      ]}
    >
      <>
        {participant && participant.role === "organizer" && <SessionLink></SessionLink>}
        <ParticipantsList inMeetingRoom />
      </>
    </Popup>
  );
};

AddParticipant.propTypes = {
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
};


export default (AddParticipant);
