export function setToken(token) {
  sessionStorage.setItem("accessToken", "Bearer " + token);
}
export function getToken() {
  return sessionStorage.getItem("accessToken");
}
export function clearStorage() {
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("publisherSettings");
  sessionStorage.removeItem("roomId");
  sessionStorage.removeItem("sessionId");
  sessionStorage.removeItem("sessionLink");
  sessionStorage.removeItem("participantId");
  sessionStorage.removeItem("apiKey");
  sessionStorage.removeItem("openTokSessionId");
  sessionStorage.removeItem("videoToken");
  sessionStorage.removeItem("participantDetails");
  sessionStorage.removeItem("participants");
  sessionStorage.removeItem("audioSource");
  sessionStorage.removeItem("patientDetails");
  sessionStorage.removeItem("videoSource");
  sessionStorage.removeItem("publishAudio");
}

export function setPublisherSettings(settings) {
  sessionStorage.setItem("publisherSettings", JSON.stringify(settings));
}

export function togglePublishAudio() {
  const publish = sessionStorage.getItem("publishAudio");
  if (publish === "true") {
    sessionStorage.setItem("publishAudio", false);
  } else {
    sessionStorage.setItem("publishAudio", true);
  }
}

export function getPublishAudio() {
  if (sessionStorage.getItem("publishAudio") === "false") return false;
  return true;
}

export function togglePublishVideo() {
  const publish = sessionStorage.getItem("publishVideo");
  if (publish === "true") {
    sessionStorage.setItem("publishVideo", false);
  } else {
    sessionStorage.setItem("publishVideo", true);
  }
}

export function getPublishVideo() {
  if (sessionStorage.getItem("publishVideo") === "false") return false;
  return true;
}

export function setAudioSource(source) {
  sessionStorage.setItem("audioSource", source);
}

export function getAudioSource() {
  sessionStorage.getItem("audioSource");
}

export function setVideoSource(source) {
  sessionStorage.setItem("videoSource", source);
}

export function getVideoSource() {
  sessionStorage.getItem("videoSource");
}
export function getPublisherSettings() {
  return JSON.parse(sessionStorage.getItem("publisherSettings")) || {};
}

export function setRoomId(roomId) {
  sessionStorage.setItem("roomId", roomId);
}

export function getRoomId() {
  return sessionStorage.getItem("roomId");
}

export function getUserId() {
  return sessionStorage.getItem("userId");
}

export function setSessionId(sessionId) {
  sessionStorage.setItem("sessionId", sessionId);
}

export function getSessionId() {
  return sessionStorage.getItem("sessionId");
}

export function setSessionLink(sessionLink) {
  sessionStorage.setItem("sessionLink", sessionLink);
}

export function getSessionLink() {
  return sessionStorage.getItem("sessionLink");
}

export function setParticipantId(sessionId) {
  sessionStorage.setItem("participantId", sessionId);
}

export function getParticipantId() {
  return sessionStorage.getItem("participantId");
}

export function setProgramId(programId) {
  sessionStorage.setItem("programId", programId);
}

export function getProgramId() {
  return sessionStorage.getItem("programId");
}

export function setIsWellness(isWellness) {
  sessionStorage.setItem("isWellness", isWellness);
}

export function getIsWellness() {
  return sessionStorage.getItem("isWellness");
}

export function getParticipant() {
  return JSON.parse(sessionStorage.getItem("participantDetails"));
}

export function getPatient() {
  return JSON.parse(sessionStorage.getItem("patientDetails"));
}

export function setParticipantList(participants) {
  const pId = getParticipantId();
  for (let index = 0; index < participants.length; index++) {
    if (participants[index].id === pId) {
      sessionStorage.setItem(
        "participantDetails",
        JSON.stringify(participants[index])
      );
    }

    if (participants[index].role === "owner") {
      sessionStorage.setItem(
        "patientDetails",
        JSON.stringify(participants[index])
      );
    }
  }

  sessionStorage.setItem("participants", JSON.stringify(participants));
}

export function getParticipantList() {
  return JSON.parse(sessionStorage.getItem("participants"));
}

export function getApiKey() {
  return sessionStorage.getItem("apiKey");
}

export function setApiKey(apiKey) {
  return sessionStorage.setItem("apiKey", apiKey);
}

export function getOpenTokSessionId() {
  return sessionStorage.getItem("openTokSessionId");
}

export function setOpenTokSessionId(apiKey) {
  return sessionStorage.setItem("openTokSessionId", apiKey);
}

export function getSessionToken() {
  return sessionStorage.getItem("videoToken");
}

export function setSessionToken(token) {
  return sessionStorage.setItem("videoToken", token);
}
