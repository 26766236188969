import React, { useState, useEffect, useRef } from "react";
import VideoSettings from "./VideoSettings";
import AddParticipant from "./AddParticipant";
import Session from "../utilities/Session";
import VideoMessage from "../common/videoMessage/index";
import "./style.css";
import VideoChat from "../Chat/VideoChat";
import { setPublisherSettings, getToken } from "../../api/storageConfig";
import { getErrorDetails, getParticipantById } from "../utilities/index";
import CallingPatient from "./CallingPatient";
import { loadRoomSettings } from "../../api/videoAPI";
import {
  displayErrorPage,
  displayEndPage,
  addUserMessage
} from "../../redux/slices/globalSlice";
import ButtonsBar from "./ButtonsBar";
import { useSelector, useDispatch } from "react-redux";
import {
  callingPatientEnded,
  callMissed,
  leaveMeetingPage,
  sessionCreated,
  terminateSession,
  toogleParticipantPopup,
  toggleSettings,
  updateSessionId,
  loadFeedbackConfig,
  toggleDisplayFeedback
} from "../../redux/slices/videoSlice";
import Whiteboard from "../Whiteboard";

const MeetingRoom = () => {
  const {
    sessionId,
    roomId,
    participants,
    isCallingPatient,
    participant,
    patient,
    showChat,
    showSettings,
    showParicipantsPopup,
    publishAudio,
    publishVideo,
    audioSource,
    videoSource,
    feedback
  } = useSelector((state) => state.video);
  const dispatch = useDispatch();

  const getPublisherSettings = () => {
    const obj = {
      audioSource: audioSource,
      videoSource: videoSource,
      publishAudio: publishAudio,
      publishVideo: publishVideo
    };

    if (participant) {
      obj.name = participant.firstName + " " + participant.lastName;
      obj.picture = participant.picture;
    } else debugger;

    return obj;
  };

  const [videoApiKey, setVideoApiKey] = useState();
  const [videoSessionId, setVideoSessionId] = useState();
  const [videoToken, setVideoToken] = useState();
  const [loading, setLoading] = useState(true);
  const [canLeaveSession, setCanLeaveSession] = useState(true);
  const [session, setSession] = useState({});
  const [sessionStarted, setSessionStarted] = useState(false);
  const [screenShared, setScreenShared] = useState(false);
  const [whiteboardDisplayed, setWhiteboardDisplayed] = useState(false);
  const [idsToName] = useState(getParticipantById(participants));
  const [videoMessage, setVideoMessage] = useState("Connecting...");
  const [timer, setTimer] = useState(0);

  const checkToken = () => {
    if (!getToken()) {
      dispatch(
        displayErrorPage({
          title: "Unauthorized",
          message: "You cannot access this page"
        })
      );

      return false;
    }
  };

  useEffect(() => {
    if (videoApiKey && videoSessionId && videoToken) {
      //TODO: use const and then remove it from window
      const videoSession = new Session({
        publisherSettings: getPublisherSettings(),
        participantsList: participants,
        apiKey: videoApiKey,
        sessionId: videoSessionId || sessionId,
        token: videoToken,
        patient: patient,
        containerElement: "meeting-room",
        mainSpeakerElement: "main-speaker",
        secondarySpeakerElement: "secondary-speakers",
        canvasId: "shared_canvas", //$canvasRef
        onPatientPickUp: () => {
          dispatch(callingPatientEnded());
        },
        onSessionCreated: () => {
          dispatch(sessionCreated()); //update redux
        },
        onSessionStarted: () => {
          setVideoMessage();
          setSessionStarted(true);
          setCanLeaveSession(false);
          setTimer(1);
        },
        onScreenShared: () => {
          setScreenShared(true);
        },
        onWhiteboardStarted: () => {
          setWhiteboardDisplayed(true);
        },
        onWhiteboardStopped: () => {
          setWhiteboardDisplayed(false);
        },
        onScreenSharedStopped: () => {
          setScreenShared(false);
        },
        onSessionEnded: (reason) => {
          setCanLeaveSession(true);

          if (reason === "forceDisconnected") {
            dispatch(terminateSession()); //update redux

            dispatch(callingPatientEnded());
            dispatch(
              displayEndPage({
                message: "Session has been ended by organizer."
              })
            );
          } else {
            dispatch(displayEndPage({}));
          }
        },
        onStreamDestroyed: () => {
          if (participant.role === "organizer") {
            setVideoMessage("The patient has disconnected");

            setTimeout(() => {
              setVideoMessage();
            }, 3000);
          }
        },
        onErrorOccured: (key) => {
          if (!key) {
            dispatch(displayErrorPage());
          } else {
            if (key === "canvas") {
              dispatch(
                addUserMessage(
                  "warning",
                  "Whiteboard cannot be used.",
                  null,
                  true
                )
              );
            }
          }
        }
      });

      setSession(videoSession);
      setLoading(false);

      if (participant && participant.role === "owner") {
        setVideoMessage("The meeting will begin when the host joins.");
      } else {
        setVideoMessage();
      }
    }
  }, [videoApiKey, videoSessionId, videoToken]);

  useEffect(() => {
    if (!session || !session.disconnectSession) return;

    return () => {
      session.disconnectSession();
    };
  }, [session]);

  useEffect(() => {
    if (
      (participant && participant.role !== "owner") ||
      !timer ||
      !feedback ||
      timer >= feedback.videoSessionTimeSec
    )
      return;

    if (timer + 1 === feedback.videoSessionTimeSec) {
      dispatch(toggleDisplayFeedback());
      return;
    }

    let _timeout = setTimeout(() => {
      console.log(timer + 1);
      setTimer(timer + 1);
    }, 1000);

    return () => {
      clearTimeout(_timeout);
    };
  }, [timer, feedback, participant]);

  useEffect(() => {
    console.log(participant);
    console.log(participant && participant.role);
    if(!participant || participant.role !== "owner") return;
    if (!feedback) {
      dispatch(loadFeedbackConfig());
    }
  }, [participant, feedback])

  useEffect(() => {
    checkToken();

    //TODO: check and update
    leaveMeetingPage(true);
    loadRoomSettings(roomId, sessionId)
      .then(({ apiKey, openTokSessionId, token, sessionId, error }) => {
        if (error) {
          const errorDetails = getErrorDetails(error);

          if (errorDetails.endSession) {
            dispatch(terminateSession());
          }

          if (errorDetails.type === "error") {
            dispatch(
              displayErrorPage({
                title: errorDetails.title,
                message: errorDetails.message
              })
            );
          } else {
            dispatch(
              displayEndPage({
                title: errorDetails.title,
                message: errorDetails.message
              })
            );
          }
        } else {
          setVideoApiKey(apiKey);
          setVideoSessionId(openTokSessionId);
          setVideoToken(token);

          if (sessionId) {
            dispatch(updateSessionId(sessionId));
          }
        }
      })
      .catch(() => {
        dispatch(
          displayErrorPage({
            title: "Error Occurred",
            message: "Error occurred on page."
          })
        );
      });
  }, []);

  useEffect(() => {
    if (session && session.publishAudio) {
      session.publishAudio(publishAudio);
    }
  }, [publishAudio]);

  useEffect(() => {
    if (session && session.publishVideo) {
      session.publishVideo(publishVideo);
    }
  }, [publishVideo]);

  useEffect(() => {
    if (!session || !session.publishAudio) return;
    //save publisher settings for find them if page is reloaded
    setPublisherSettings(getPublisherSettings());
  }, [publishAudio, publishVideo, session]);

  useEffect(() => {
    //alert organizer if he reload page => session terminated
    if (canLeaveSession) window.onbeforeunload = undefined;
    else if (participant && participant.role === "organizer") {
      window.onbeforeunload = () => {
        return true;
      };
    }
  }, [canLeaveSession, participant]);

  return (
    <div className="meeting-room-container">
      <div
        id="meeting-room"
        style={!showChat ? { width: "100%" } : { width: "calc(100% - 360px)" }}
      >
        {videoMessage && <VideoMessage message={videoMessage} />}
        {showSettings && (
          <VideoSettings
            handleSave={(newAudioSource, newVideoSource) => {
              if (newAudioSource) {
                session.changeAudioSource(newAudioSource);
              }
              if (newVideoSource) {
                session.changeVideoSource(newVideoSource);
              }

              dispatch(toggleSettings());
            }}
          ></VideoSettings>
        )}
        {showParicipantsPopup && (
          <AddParticipant
            handleSave={() => {
              dispatch(toogleParticipantPopup());
            }}
          ></AddParticipant>
        )}

        <div id="secondary-speakers"></div>
        <div id="main-speaker-container">
          <div
            id="main-speaker"
            className="speaker-alone"
            style={{ display: whiteboardDisplayed ? "none" : "initial" }}
          >
            {!sessionStarted && (
              <CallingPatient patient={patient} ringing={isCallingPatient} />
            )}
          </div>
          {whiteboardDisplayed && (
            <Whiteboard
              whiteboardReady={($ref) => {
                session.whiteBoard($ref);
              }}
              containerId={"main-speaker-container"}
              handleError={(message) => {
                addUserMessage("error", message);
              }}
            />
          )}
        </div>
        {!loading && (
          <ButtonsBar
            enableScreenShare={participant && participant.role === "organizer"}
            screenShared={screenShared || whiteboardDisplayed}
            handleStopShareScreen={() => {
              session.stopShareScreen();
            }}
            handleSelectPresent={(selection) => {
              if (selection === "share_screen") {
                session.shareScreen();
              } else {
                setWhiteboardDisplayed(true);
              }
            }}
            handleEndSession={() => {
              if (!sessionStarted) {
                dispatch(callMissed());
              }

              if (
                sessionStarted &&
                participant &&
                participant.role === "organizer"
              ) {
                dispatch(terminateSession());
              }

              setCanLeaveSession(true);
              session.disconnectSession();
            }}
          />
        )}
      </div>
      {screenShared && (
        <div className="share-screen-message">{`To avoid infinity mirror, we don't recommend sharing your entire screen.`}</div>
      )}
      <VideoChat
        isOpen={showChat}
        patientId={patient && patient.id}
        idsToName={idsToName}
      ></VideoChat>
    </div>
  );
};

export default MeetingRoom;
